@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
.report-analysis-box {
  height: 100%;
  @include base-pd(0px 30px);
  :global {
    .result-box {
      height: calc(100% - 60px);
      @include base-pd(20px 0px);

      .result-content-box {
        height: 100%;
        @include base-font;
        @include base-font-size;
        .content-tool-box {
          display: flex;
          justify-content: space-between;
          .content-tool {
            display: flex;
          }
        }
        .text-box{
          flex: 1;
          margin-right: 1rem;
          text-align: justify;
          .desc{
            color: #c2370b;
          }
          .time-text{
            color: #214b62;
          }
        }
        .analysis-table-box {
          height: calc(100% - 130px);
          overflow: auto;
          // input z-index超出thead 會穿過
          .seach-input {
            z-index: 0;
          }
        }
      }
    }

    .word-box {
      height: calc(100% - 60px);
    }

    .analysising-box {
      margin-top: 20px;
      padding: 16px 26px;
      border-radius: 4px;
      border: 1px dashed #86b2c8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .analysis-word{
        @include base-font(#214B62, Inter);
        @include base-font-size(18px, 500);
      }

      .refresh-box{
        margin: 1rem 0;
      }
      .progress-box{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .percent {
          margin-left: 1rem;
          @include base-font(#9eadc6);
          @include base-font-size(13px, 400);
        }
      }
    }
  }
}
