@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
:local(.sustainReport-box) {
  display: flex;
  height: 100%;

  :global {
    .mt-16-p {
      margin-top: 16px;
    }
    .main-title {
      @include base-font;
      @include base-font-size(26px, 500);
    }
    .sub-title {
      @include base-font;
      @include base-font-size(20px, 500);
      margin-right: 20px;
    }
    .result-title-box {      
      display: flex;
      align-items: start;
    }

    .section-title {
      padding: 8px 16px;
      display: flex;
      @include base-font;
      @include base-font-size(22px,500);
      cursor: pointer;
      > div:nth-child(1){
          img {
            &.hide {
              transform: rotate(180deg);
            }
          }
        }
      > div:nth-child(2){
        margin-left: 8px;
      }
    }
    .section-content {
      background-color: #F9FBFC;
      padding: 16px;
      border-top: 1px solid #DBE8EF;
      .section-subtitle {
        @include base-font;
        @include inquiry-base-font-size;
        padding-top: 16px;
      }
    }
    
    .right-box {
      border-left: 1px solid rgba(134, 178, 200, 0.3);
      @include base-pd;

      height: 100%;
      @include base-right-box;
      max-width: calc(100% - 64px);
      &.has-filter {
        max-width: calc(100% - 260px);
      }

      .tool-box {
        display: flex;
        justify-content: space-between;
        .tool-item-box {
          @include inquiry-base-font-size;
          display: flex;
          > div {
            margin-left: 1rem;
          }
          .switch-box{
            .icon-item{
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
          }
        }
      }

      .empty-box{
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
      }
    }
    
    .result-box {
      margin-top: 20px;
      height: calc(100% - 70px);

      @include base-font;
      @include inquiry-base-font-size;

      .provider-word{
        color: #79A0B4;
      }
      .default-word{
        color: #C2370B;
      }
      .table-box {
        width: 100%;
        padding: 0 5px;
        th.sticky-top-41 {
          top: 41px;
        }
      }
      .companyItem {
        border: 1px solid #DBE8EF;
        border-radius: 3px;
        margin-top: 16px;
        
      }
    }

    .left-box {
      @include base-pd;
      @include base-right-box(260px);
      /* 移動動畫 */
      transition: width 0.5s;
      transition-timing-function: linear;
      .toggle-box {
        display: flex;
        justify-content: space-between;

        img {
          width: 22px;
          cursor: pointer;
        }
      }

      .search-item-box {
        margin: 20px 0;
      }

      .empty-box {
        display: flex;
        align-items: center;
        justify-content: center;
        @include base-font(#79a0b4);
        @include inquiry-base-font-size;
      }

      .tree-box {
        height: calc(100vh - 314px);
        overflow-y: scroll;
        overflow-x: hidden;
        .tree-title {
          display: flex;
          align-items: center;
          line-height: normal;
          margin-bottom: 0.75rem;
          @include base-font;
          @include base-font-size(18px, 500);
          img {
            width: 20px;
            margin-right: 5px;
          }
        }

        ul {
          padding-left: 0;
          li {
            display: flex;
            list-style: none;
            @include base-font(#008dc1);
            @include inquiry-base-font-size;

            border-radius: 4px;
            cursor: pointer;
            padding: 6px 6px 6px 25px;
            &:hover {
              background-color: rgba(0, 141, 193, 0.1);
            }

            &.active {
              color: #fff;
              background-color: #008dc1;
            }

            .check-img {
              margin-right: 6px;
            }
          }
        }
      }

      &.hide {
        width: 64px;
        padding: 20px;
        transition: width 0.5s;
        transition-timing-function: linear;
        .img-tool {
          display: flex;
          flex-direction: column;
          img {
            width: 22px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

:global{
  
  .show-result-box {
    border-radius: 10px;
    background-color: #fff;
    padding: 1rem;
    &.print-box {
      margin-right: 20px;
      width: -webkit-fill-available;
      position: absolute;
      .table-box {
        height: 100%;
      }
    }
  }
}
