.confirm-modal-box {
  :global {
    .btn-box {
      display: flex;
      width: 100%;
      justify-content: end;
      .btn-item {
        width: 120px;
        min-width: 120px;
      }
    }
  }
}

//- alertMessage
.alertMessage {
  :global {
    .modal-header {
      display: none;
    }
    .modal-body {
      padding: 2rem 2rem 0.5rem;
    }
    .modal-footer {
      border-top: 0;
      .btn-box {
        .btn-item:nth-child(1) {
          display: none;
        }
      }
    }
  }
}
