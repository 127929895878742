@import "/src/scss/helpers/variables";

.home-box {
  padding: 1.5rem 30px;
  position: relative;
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
}

.operation-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;

  :global {
    & .select-item {
      display: flex;
      align-items: center;
      > span {
        //- no wrap
        white-space: nowrap;
        margin-right: 6px;
        color: $color-1;
      }
    }
    & .left,
    & .right {
      display: flex;
      align-items: center;
    }
    & .left {
      .select-item:not(:first-child) {
        margin-left: 1rem;
      }
    }
    & .right {
      .select-item:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

.home-content-box {
  padding: 1rem 0;
  position: relative;
  border-top: 1px solid #86b2c84d;
  height: calc(100% - 46.5px);
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: hidden;
  // overflow-y: hidden;

  .leftBox {
    width: 33.3%;
  }
  .rightBox {
    width: 66.6%;
    .col {
      display: flex;
      .chart-box {
        width: 50%;
      }
    }
  }
}

.chart-box {
  border: 1px solid #86b2c84d;
  border-radius: 4px;
}

.data-change-list {
  margin: 1rem 0.5rem 0;
  border-top: 1px solid #86b2c84d;
  padding: 1rem;
  .title {
    font-size: 20px;
    color: #214b62;
  }
  .list {
    overflow-y: auto;
    padding: 0.5rem;
    // height: calc(100% - 406px);
    .item {
      font-size: 16px;
      color: #008dc1;
      margin: 0.25rem 0;
    }
  }
}
.export-box {
  margin: 1rem 0.5rem 0;
  position: relative;
  :global {
    .operator-box {
      display: grid;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.table-box {
  margin: 1rem 0.5rem 0;
  position: relative;
  height: calc(406px);
  .home-table {
  }
  :global {
    table {
      tbody {
        tr {
          td.lastTrFirstTd {
            border-bottom-left-radius: 4px;
          }
          &:last-child {
            td:last-child {
              border-bottom-right-radius: 4px;
            }
          }
        }
      }
    }
  }
}

.no-data {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100%;
}
