@import "./../helpers/mixin";
body {
  background: linear-gradient(180deg, #D9F5FF 0%, #BCE0F3 100%);
  height: 100vh;
  font-family: "Noto Sans TC", sans-serif;
}

@font-face {
  font-family: 'Noto Sans TC';
  src: url('../../../public/assets/font/NotoSansTC-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../../public/assets/font/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.root-box {
  .layout-box {
    height: calc(100vh - 70px);
    overflow-y: auto;
    display: flex;

    .layout-container-box {
      height: inherit;
      width: 100%;
      padding: 20px 20px 20px 0;
      a {
        text-decoration: none;
      }
    }

    // 有nav
    .nav-content-box {
      // max-width: calc(100vw - 140px);
      height: calc(100% - 48px);
      @include base-layout-box;
    }

    // 無nav
    .no-nav-content-box {
      // max-width: calc(100vw - 140px);
      height: 100%;
      @include base-layout-box;
    }
  }

  // title 返回固定樣式
  .title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #dbe8ef;
    .tool-box {
      display: flex;
      align-items: center;
    }
    .back-box {
      display: flex;
      align-items: center;
      .title {
        font-feature-settings: "clig" off, "liga" off;
        @include base-font(#214b62);
        @include base-font-size(20px, 500);
        line-height: 36px; /* 180% */
        letter-spacing: 1px;
      }
      .back-icon {
        cursor: pointer;
        height: 24px;
        width: 24px;
        background-image: url("../../../public/assets/images/buttonIcon/back-icon.svg");
      }
    }
  }

  // 主要區塊title
  .main-title {
    font-size: 20px;
    font-weight: 500;
    @include base-font;
  }
}

.scroll {
  // 大部分y軸都需要
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    // background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    // background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(134, 178, 200, 0.4);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(134, 178, 200, 0.6);
  }
}

.modal-dialog {
  .modal-content {
    .modal-header {
      .modal-title {
        @include base-font($color-6);
        @include base-font-size(20px, 500);
        letter-spacing: 2px;
      }
    }
    .modal-body {
      @include base-font($color-1);
      @include base-font-size;
      line-height: 29px;
    }
  }
}

.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #008dc1;
  border: 1px solid #008dc1;
  outline: none;
}
.btn-secondary {
  background-color: #fff;
  color: #008dc1;
  border: 1px solid #008dc1;
  &:hover {
    color: #fff;
    background-color: #42afd7;
    border-color: #008dc1;
  }
}
.NumBlock {
  padding: 6px 5px;
}

// disable 區塊 (radio checkbox)
.disabled-block {
  @include custom-disabled;
  .custom-checkbox,
  .custom-radio {
    &:hover {
      background-color: inherit;
    }
  }
}

// 沒公司資料呈現的文字
.no-company-data-word{
  color: #c2370b;
  font-weight: bold;
}

@media print {
  body {
    // 印模式下 chrome 中元素的背景顏色會消失，要加入該元素
    -webkit-print-color-adjust: exact;
  }
}

//#region ------------- refresh-------------
.refresh {
  width: 22px;
  height: 22px;
  animation: roration;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes roration {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
//#endregion ------------- refresh-------------
