@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";


:global {
    .open-operatingRecord-modal {
        .modal-dialog {
            margin-top: 12vh;
            width: 900px;
        }
    }
}

.open-operatingRecord-modal-content {
    display: flex;
    flex-direction: column;
    max-height: 688px;

    :global {
        .beforeAfter_text_box {
            display: flex;
            gap: 10px;
            .beforeAfter_text {
                width: 50%;
                color: #214B62;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: "Noto Sans";
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 13px;
            }
        }
        .result-box {
            position: relative;
            display: flex;
            gap: 20px;
            width: 100%;
            .beforeEditing::-webkit-scrollbar,
            .afterEditing::-webkit-scrollbar {
                display: none;
            }
            .beforeEditing,
            .afterEditing {
                overflow-y: auto;
                width: 50%;
                height: 560px;
                border: 1px solid #dbe8ef;
                .normal_text {
                    padding: 6px;
                }
                .beforeItemDifferent {
                    border-top: 1px solid white;
                    border-bottom: 1px solid white;
                    border-radius: 4px;
                    padding: 6px;
                    background:#F4F8FA;
                    color:rgba(33, 75, 98, 0.50);
                  }
                .afterItemDifferent {
                    border-top: 1px solid white;
                    border-bottom: 1px solid white;
                    border-radius: 4px;
                    padding: 6px;
                    color: #008DC1;
                    background:  #E5F4F9;
                    
                    &:hover {
                        color: white;
                        background:  #008DC1;
                    }
                }
                
                
            }
            .scrollBarBox {
                position: relative;
                right: 5px;
                height: "560px";
                width: "12px";
                background: "transparent";
                overflow: "auto";
            }
        }
    }

}