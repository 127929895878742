@import "/src/scss/helpers/mixin";

.sustain-report-info-box {
  height: 100%;
  @include base-pd(0px 30px);
  :global {
    .title-box {
      border-bottom: 1px solid #dbe8ef;
    }
    .sustain-content-box {
      height: calc(100% - 58px);
      display: flex;
      > .left-box {
        max-width: 230px;
        height: inherit;
        @include base-left-box;
        padding: 20px 30px 20px 0px;

        .tree-box {
          height: calc(100vh - 210px);
          overflow-y: scroll;
          overflow-x: hidden;
          .tree-title {
            display: flex;
            align-items: center;
            line-height: normal;
            margin-bottom: 0.75rem;
            @include base-font;
            @include base-font-size(14px, 500);
            img {
              width: 20px;
              margin-right: 5px;
            }
          }

          ul {
            padding-left: 0;
            li {
              list-style: none;
              color: #008dc1;
              font-family: Noto Sans TC;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              border-radius: 4px;
              cursor: pointer;
              padding: 6px 20px;
              &:hover {
                background-color: rgba(0, 141, 193, 0.1);
              }

              &.active {
                color: #fff;
                background-color: #008dc1;
              }
            }
          }
        }
      }
      > .right-box {
        max-width: calc(100vw - 430px);
        @include base-right-box;
        position: relative;
        border-left: 1px solid rgba(134, 178, 200, 0.3);
        padding: 20px 0 20px 20px;
      }
    }
  }
}
