@import "/src/scss/helpers/mixin";

.propaganda-box {
  @include base-pd(0 30px);
  height: inherit;
  .title {
    font-feature-settings: "clig" off, "liga" off;
    @include base-font(#214b62);
    @include base-font-size(20px, 500);
    line-height: 36px;
    letter-spacing: 1px;
    padding: 10px 0;
    border-bottom: 2px solid #dbe8ef;
  }
  .propaganda-content-box{
    height: calc(100% - 80px);
    .propaganda-item-box {
      .desc {
        @include base-font(#214b62);
        @include base-font-size(16px, 700);
        margin: 20px 0;
      }
  
      .file-box {
        .file-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 16px;
          border-radius: 4px;
          border: 1px solid #dbe8ef;
          &:hover{
              background-color: #dbe8ef;
              cursor: pointer;
          }
        }
        .file-size {
          @include base-font(#79a0b4);
          @include base-font-size;
          line-height: 24px;
          white-space: nowrap;
        }
      }
    }
  }

}
