@import "/src/scss/helpers/mixin";
:local(.indcator-list-box) {
  height: inherit;
  @include base-pd(0 30px);
  :global {
    .title {
      color: #214b62;
      font-feature-settings: "clig" off, "liga" off;

      /* TWSE/Page title */
      font-family: Noto Sans TC;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px; /* 180% */
      letter-spacing: 1px;
      padding: 10px 0;
      border-bottom: 2px solid #dbe8ef;
    }

    .result-box {
      max-height: calc(100% - 255px);
    }

    .topBarDesc {
      color: #214b62;
      font-family: Noto Sans TC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      .second-desc {
        color: #008dc1;
      }
    }

    .table-container {
      .status {
        &.no-declared {
          color: #c2370b;
        }
      }

      .end-date {
        @include base-font(#008dc1, Inter);
        line-height: 24px; /* 171.429% */
      }

      td {
        .operator-Btn {
          width: auto;
          padding: 0px 30px;
          min-width: auto;
        }
      }
    }
  }
}

:global {
  .root-box .layout-box .layout-container-box {
    .reference {
      margin-top: 1rem;
      .reference-title {
        color: #214b6280;
      }
      a {
        color: #008dc1;
        text-decoration: underline;
      }
      .reference-source {
        display: flex;
      }
      ol {
        padding-left: 1.5rem;
        color: #008dc1;
      }
      .desc{
        font-size: 1rem;
      }
    }
  }
}
