.root-box.admin {
  .layout-box {
    .layout-container-box {
      width: calc(100vw - 180px);
    }
  }
}

.noPermission {
  width: 100%;
  height: calc(100vh - 70px);
  > div {
    height: 100%;
  }
}

.admin-sidebar-nav {
  min-width: 180px;
  width: 180px;
  > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    > li {
      &:first-child {
        margin-top: 80px;
      }
      > a {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-left: 10px;
        padding: 12px 10px;
        &[class="active"] {
          background-color: #fff;
          border-radius: 20px 0px 0px 20px;
          padding-left: 20px;
        }
        > img {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }
        > div {
          color: #008dc1;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
