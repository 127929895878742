@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";

:local(.OperatingRecord) {
    height: 100%;
    padding: 0px;

    :global {
        .title {
            font-feature-settings: "clig" off, "liga" off;
            @include base-font(#214b62);
            @include base-font-size(20px, 500);
            line-height: 36px;
            /* 180% */
            letter-spacing: 1px;
        }

        .data-input {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .custom-date-range-picker.react-daterange-picker {
                display: flex;
                align-items: center;
                width: 40%;
                min-width: 400px;
                position: relative;
                height: 42px;
                border: 1px solid #86b2c8;
                padding: 9px 16px;
                border-radius: 4px;
                background-color: #f9fbfc;

                .react-daterange-picker__wrapper {
                    border: 0;

                    input {
                        color: #000;
                    }

                    .react-daterange-picker__range-divider {
                        color: "#86b2c8";
                    }

                    .react-daterange-picker__clear-button,
                    .react-daterange-picker__calendar-button {
                        svg {
                            filter: invert(69%) sepia(17%) saturate(590%) hue-rotate(155deg) brightness(96%) contrast(91%);
                        }
                    }
                }

                .react-daterange-picker__calendar {
                    z-index: 10;
                }
            }
            .upload-btn {
                background-color: transparent;
                display: flex;
                align-items: center;
                border: none;
                color:  #008DC1;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px; /* 171.429% */
                gap: 8px;
            }
        }

        .select-item {
            width: 20%;
            min-width: 200px;
            >div{
                >input {
                    width: 100%;
                }
            }
        }

        .result-box {
            height: calc(100% - 185px);
            border-right: 1px solid #dbe8ef;
            border-left: 1px solid #dbe8ef;
            .main_table {
                width: 100%;
                .th {
                    padding: 10px;
                    color: #214B62;
                    background-color: #DBE8EF;
                    text-align: center;
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 700;
                    border-right: 1px solid #fff;
                }
                .even-class,
                .odd-class {
                    padding: 10px 0;
                    border: 1px solid  #DBE8EF;
                    text-align: center;
                    color: #214B62;
                    font-size: 14px;
                    font-weight: 400;
                }
                .odd-class {
                    background-color: #F4F8FA;
                }
                .chapters_button {
                    background-color: transparent;
                    border: none;
                    color: #008DC1;
                }
            }
        }
    }
}