@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
:local(.indicator-list) {
  height: 100%;
  :global {
    .title {
        color: #214b62;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Noto Sans TC;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px; /* 180% */
        letter-spacing: 1px;
        padding: 10px 0;
        border-bottom: 1px solid #dbe8ef;
      }
    .tool-box {
      display: flex;
      justify-content: space-between;
      .sel-item {
        color: #214B62;
        font-family: Noto Sans TC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-right: 16px;
        margin-right: 10px;
        border-right: 1px solid rgba(134, 178, 200, 0.30);
        height: 20px;
      }
      .icon-button {
        margin: 0 4px;
      }
    }
    .result-box {
        margin-top: 20px;
        display: flex;
        height: calc(100% - 210px);
        border-right: 1px solid #dbe8ef;
        border-left: 1px solid #dbe8ef;
        .table-box {
          width: 100%;
          padding: 0;
        }
    }
    .lock {
      cursor: initial !important;
      .text {
        color: rgba(134, 178, 200, 0.70) !important;
      }
    }
    .hover {
      padding: 5px 0 5px 5px;
      margin: 0px;
      &:hover {
        border-radius: 4px;
        background-color: #E5F4F9;
      }
    }
    .h-20 {
      height: 20px;
    }
    .page {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}