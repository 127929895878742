@import "/src/scss/helpers/mixin";
:local(.link-icon-button-box) {

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 6px;
    :hover{
        text-decoration: underline;
    }
  :global {
    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }

    .text {
      @include base-font($theme-color-1,Inter);
      line-height: 24px;
    }
  }
}
