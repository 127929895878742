@import "/src/scss/helpers/mixin";

.rg-step1-box {
  overflow-y: auto;
  height: 100%;
  padding-bottom: 20px;
  :global {
    .title-box {
      padding: 0;
      border-bottom: 0px;
      margin-bottom: 20px;
    }
    
    .color1{
      color: #B21016;
    }

    .color2{
      color: #138535;
    }

    .color3{
      color: #007AAE;
    }
    .desc {
      @include base-font(#79a0b4);
      @include base-font-size(16px);
      .mark {
        padding: 0;
        background-color: transparent;
      }
      .operator-desc{
        display: flex;
        img{
          margin-right: 0;
        }
      }
      margin-bottom: 20px;
    }

    .custom-checkbox {
      padding: 0;
      &:hover {
        background-color: transparent;
      }
      input[type="checkbox"] + span {
        padding-left: 13px;
      }
    }

    .table-result {
      overflow-y: auto;
      overflow-x: auto;
      // 樹狀css
      .tree-table-box {
        width: 100%;

        padding-left: 0;
        background-color: #fff;

        .wrapper-item {
          // border-top: 1px solid #dbe8ef;
          // border-left: 1px solid #dbe8ef;

          .display-none {
            display: none;
          }
        }

        .col-item {
          border-left: 1px solid #dbe8ef;
          height: 100%;
          padding: 8px 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .main-row {
          display: flex;
          align-items: center;
          background-color: #fff;

          border-top: 1px solid #dbe8ef;
          border-bottom: 1px solid #dbe8ef;
          height: 42px;
          text-align: center;

          &.odd{
            background-color: #f4f8fa;
          }
          .icon-move {
            cursor: move;
          }

          .check-box {
            width: 48px;
          }

          .move-box {
            width: 48px;
          }

          .code {
            min-width: 130px;
            justify-content: start;
          }

          .chapter-title {
            @include base-font(#292121);
            @include base-font-size(14px);
            padding: 8px 16px;
            min-width: 200px;
            width: 200px;
            flex-grow: 1;
            display: block;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .category {
            width: 230px;
          }
          .extend {
            width: 230px;
          }
        }
      }
    }
  }
}
