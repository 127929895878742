@import "/src/scss/helpers/variables";

.transaction-request-box {
  padding: 1.5rem 30px;
  position: relative;
}

.operation-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #86b2c84d;
  padding-bottom: 1rem;
  :global {
    & .select-item {
      display: flex;
      align-items: center;
      > span {
        //- no wrap
        white-space: nowrap;
        margin-right: 6px;
        color: $color-1;
      }
    }
    & .left,
    & .right {
      display: flex;
      align-items: center;
    }
    & .left {
      .select-item:not(:first-child) {
        margin-left: 1rem;
      }
    }
    & .right {
      .select-item:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

.transaction-table-box {
  margin-top: 1rem;
  position: relative;
  height: calc(100vh - 345px);
  // border-left: 1px solid #dbe8ef;
  // border-right: 1px solid #dbe8ef;
  overflow-x: auto;
}

.table-pagination-box {
  padding: 1rem;
  border: 1px solid #dbe8ef;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

table.transaction-table {
  min-width: 100%;
  &.transaction-table-uni {
    width: max-content;
    tr {
      td {
        max-width: 600px;
      }
    }
    tbody td:nth-child(1),
    tbody td:nth-child(2),
    tbody td:nth-child(3),
    thead th:nth-child(1),
    thead th:nth-child(2),
    thead th:nth-child(3) {
      position: sticky;
      left: 0;
    }

    thead th:nth-child(1),
    thead th:nth-child(2),
    thead th:nth-child(3) {
      z-index: 1;
    }

    tbody tr:nth-child(odd) {
      td:nth-child(1),
      td:nth-child(2),
      td:nth-child(3) {
        background-color: #fff;
      }
    }

    tbody tr:nth-child(even) {
      td:nth-child(1),
      td:nth-child(2),
      td:nth-child(3) {
        background-color: #f4f8fa;
      }
    }

    tbody tr:nth-child(even) {
      td:nth-child(1),
      td:nth-child(2),
      td:nth-child(3) {
        background-color: #f4f8fa;
      }
    }

    tbody td:nth-child(1),
    thead th:nth-child(1) {
      width: 160px;
    }

    tbody td:nth-child(2),
    thead th:nth-child(2) {
      width: 120px;
      left: 160px;
    }

    tbody td:nth-child(3),
    thead th:nth-child(3) {
      left: 280px;
    }
  }

  th {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        margin-left: 5px;
      }
    }
  }

  tr:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }
}
