@import "/src/scss/helpers/variables";
:local(.tools-box) {
  width: 100%;
  height: calc(100vh - 70px);
  padding: 1rem;
  overflow-x: hidden;

  :global {
    .title {
      font-size: 20px;
      font-weight: 700;
      color: $color-5;
    }

    .row {
      border: 1px solid #008dc1;
      padding: 1rem;
      border-radius: 4px;
      .component-title {
        color: cornflowerblue;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
      }
    }
  }

}
