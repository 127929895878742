@import "/src/scss/helpers/mixin";

.rg-step4-box {
  height: 100%;

  :global {
    .result-box {
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      .finish-bg {
        width: 320px;
      }
      .sentence1 {
        @include base-font(#214b62);
        @include base-font-size(20px, 500);
        margin: 20px 0;
        text-align: center;
      }
      .sentence2 {
        @include base-font(#214b62);
        @include base-font-size(14px);
        text-align: center;
        line-height: 180%;
        margin-bottom: 20px;
        .link {
          color: #008dc1;
          cursor: pointer;
        }
      }
    }
  }
}
