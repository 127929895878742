@import "/src/scss/helpers/variables";

.propaganda-file-list-box {
  padding: 1rem;
  position: relative;
}

.propaganda-file-table-box {
  margin-top: 1rem;
  position: relative;
  height: calc(100vh - 315px);
  border-left: 1px solid #dbe8ef;
  border-right: 1px solid #dbe8ef;
}

.propaganda-file-list-box {
    .operation-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        :global {
          & .select-item {
            display: flex;
            align-items: center;
            > span {
              //- no wrap
              white-space: nowrap;
              margin-right: 6px;
              color: $color-1;
            }
          }
          & .left,
          & .right {
            display: flex;
            align-items: center;
          }
          & .left {
            .select-item:not(:first-child) {
              margin-left: 1rem;
            }
          }
          & .right {
            .select-item:not(:last-child) {
              margin-right: 1rem;
            }
          }
        }
      }
}

.setting-checkbox{
  :global{
    .custom-checkbox{
      &:hover{
        background-color:unset;
      }
    }
  }
}
