@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
:local(.ghg-reduction-info-box) {
  display: flex;
  height: 100%;
  :global {
    .main-title {
      @include base-font;
      @include base-font-size(26px, 500);
    }
    .sub-title {
      @include base-font;
      @include base-font-size(20px, 500);
    }
    .result-title-box {      
      height: fit-content;
      display: flex;
      align-items: center;
    }

    .right-box {
      height: 100%;
      @include base-right-box;
      @include base-pd;
      
      max-width: calc(100% - 64px);
      &.has-filter {
        max-width: calc(100% - 260px);
      }

      .tool-box {
        display: flex;
        justify-content: space-between;
        .tool-item-box {
          @include inquiry-base-font-size;
          display: flex;
          > div {
            margin-left: 1rem;
          }
        }
      }
      .result-box {
        margin-top: 20px;
        height: calc(100% - 40px);
    
      }
    }

    .left-box {
      border-right: 1px solid rgba(134, 178, 200, 0.3);
      @include base-left-box(260px);
      @include base-pd;
      /* 移動動畫 */
      transition: width 0.5s;
      transition-timing-function: linear;
      .toggle-box {
        display: flex;
        justify-content: space-between;

        img {
          width: 22px;
          cursor: pointer;
        }
      }

      .search-item-box {
        margin: 20px 0;
      }

      &.hide {
        width: 64px;
        padding: 20px;
        transition: width 0.5s;
        transition-timing-function: linear;
        .img-tool {
          display: flex;
          flex-direction: column;
          img {
            width: 22px;
            margin-bottom: 10px;
          }
        }
      }
    }

    .table-box {
      display: flex;
      flex: 1 1;
      .table-item {
        flex: 1;
        width: 100%;
        padding: 0 5px;

        .table-container > td {
          height: 40px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .empty-box{
      @include inquiry-base-font-size;
    }
  }
}

:global {
  .content-modal-box {
    .detail-content-box {
      height: 500px;
      .section-box {
        @include base-font;
        border-bottom: 1px solid #dbe8ef;
        margin-bottom: 0.5rem;
        .section-title {
          @include base-font-size(24px, 700);
        }
        .question-box {
          margin: 0.5rem 0;
          .question {
            @include inquiry-base-font-size;
          }
          .control {
            margin-top: 0.5rem;
            margin-left: 26px;
          }

          .display-val {
            white-space: pre-wrap;
            @include inquiry-base-font-size;
          }
        }
      }
      .company-title{
        @include inquiry-base-font-size;

      }
    }
  }
  
  .show-result-box{
    margin-right: 20px;
    border-radius: 10px;
    background-color: #FFF;
    padding: 1rem;
    &.print-box{
      position: absolute;
      .table-box{
        height: 100%;
      }
    }
  }
}
