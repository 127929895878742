@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
:local(.mail-box) {
  height: 100%;
  padding: 0 30px;
  :global {
    .title {
      color: #214b62;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Noto Sans TC;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px; /* 180% */
      letter-spacing: 1px;
    }
    .tool-box {
      display: flex;
      justify-content: space-between;
      .sel-item {
        color: #214b62;
        font-family: Noto Sans TC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-right: 16px;
        margin-right: 10px;
        border-right: 1px solid rgba(134, 178, 200, 0.3);
        height: 20px;
      }
      .icon-button {
        margin: 0 4px;
      }
    }
    .result-box {
      margin-top: 20px;
      display: flex;
      height: calc(100% - 220px);
      border-right: 1px solid #dbe8ef;
      border-left: 1px solid #dbe8ef;
      .table-box {
        width: 100%;
        padding: 0;
      }
    }
  }
}
