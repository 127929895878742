@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
:local(.Edit) {
  height: 100%;
  padding: 0px;
  :global {
    .tool-box {
        display: flex;
        justify-content: end;
        padding-bottom: 10px;
    }
    .result-box {
        display: flex;
        height: calc(100% - 147px);
        .standardTable-block {
          width: 440px;
          position: sticky;
          left: 0;
          z-index: 30;
          background-color: #fff;
          height: fit-content;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
          .sticky-table {
            th {
              white-space: nowrap;
              min-width: 100px;
            }
            td {
              div {
                &.fixIndicator {
                  height: 40px;
                  white-space: pre-line;
                  word-break: break-word;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  }
                }
            }
          }
        }
         &::-webkit-scrollbar-track-piece {
          margin-left: 440px;
        }
        .standardTable-block2 {
          /*width: calc(100% - 440px);
          /*position: sticky;
          /*left: 0;*/
          z-index: 29;
          background-color: #fff;
          /*height: fit-content;*/
          th {
            white-space: nowrap;
            min-width: 100px;
          }
          td {
            div {
            &.fixIndicator {
                height: 40px;
                white-space: pre-line;
                word-break: break-word;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
            }
          }
        }
    }
  }
}
