@import "/src/scss/helpers/mixin";

:local(.sustain-report-list-box) {
  height: inherit;
  @include base-pd(0 30px);
  :global {
    .title {
      font-feature-settings: "clig" off, "liga" off;
      @include base-font(#214b62);
      @include base-font-size(20px, 500);
      font-family: Noto Sans TC;
      line-height: 36px;
      letter-spacing: 1px;
      padding: 10px 0;
      border-bottom: 2px solid #dbe8ef;
    }
    .desc {
      @include base-font(#79a0b4);
      @include base-font-size;
    }
    
    .result-box {
      max-height: calc(100% - 215px);
    }

    .table-container {
        td{
          min-width: 80px;
        }
        .status {
          &.no-declared {
            color: #c2370b;
          }
        }
  
        .end-date {
          @include base-font(#008dc1, Inter);
          line-height: 24px; /* 171.429% */
        }
        .func-td{
          width: 125px;
        }
        .func-btn-box{
          display: flex;
          flex-direction: column;
          button{
  
            margin-bottom: 0.5rem;
          }
        }
      }
  }
}
