.questionnaire-edit-box {
	min-height: 100%;
	position: relative;
	:global {
		.questionnaire-edit-title-box {
			margin-bottom: 1rem;
			> .info {
				display: flex;
				align-items: center;
				justify-content: space-between;
				> .left {
					color: #214b62;
					font-size: 20px;
					font-weight: 500;
				}
				> .right {
					display: flex;
					align-items: center;
					> button {
						word-break: keep-all;
						&.saveAndAdd {
							min-width: unset;
						}
						&:not(:last-child) {
							margin-right: 10px;
						}
					}
				}
			}
		}
		.questionnaire-comment {
			margin: 1rem 0;
			color: #79a0b4;
			font-size: 1rem;
		}
		.required-comment {
			color: #214b62;
			font-weight: 500;
			.required-icon {
				color: #c2370b;
			}
		}
		.no-data {
			height: calc(100% - 54px);
		}
		.questionnaire-edit-out-box {
			border-radius: 4px;
			border: 1px solid #dbe8ef;
			padding: 1rem;
			.questionnaire-edit-in-box {
				.section-box {
					.section-title-box {
						display: flex;
						align-items: flex-start;
						border: 1px solid #dbe8ef;
						border-top-left-radius: 4px;
						border-top-right-radius: 4px;
						padding: 0.5rem;
						cursor: pointer;
						> img {
							margin-right: 0.75rem;
							&.isSectionCollapse {
								transform: rotate(180deg);
							}
						}
						> .info {
							> .title {
								color: #214b62;
								font-size: 18px;
								line-height: 24px;
								font-weight: 500;
							}
							> .comment {
								margin-top: 2px;
								color: #79a0b4;
								font-size: 14px;
							}
						}
					}
					&:not(:last-child) {
						margin-bottom: 12px;
					}
					.section-controls-box {
						border: 1px solid #dbe8ef;
						border-top: 0;
						border-bottom-left-radius: 4px;
						border-bottom-right-radius: 4px;
						padding: 0.5rem 1rem;
						.control-box {
							> .title {
								font-size: 1rem;
								font-weight: 400;
							}
						}
					}
				}

				.control-box {
					padding: 0.5rem;
					> .title {
						color: #214b62;
						font-weight: 500;
						font-size: 18px;
						display: flex;
						align-items: center;
						> .required-icon {
							color: #c2370b;
						}
						> .comment-image-tip {
							margin-left: 0.5rem;
						}
					}
					> .comment {
						margin-top: 2px;
						color: #79a0b4;
						font-size: 14px;
					}
					> .value-box {
						margin-top: 12px;
						padding-bottom: 12px;
						border-bottom: 1px solid #dbe8ef;
						&.radio-value-box,
						&.checkbox-value-box {
							// margin-top: 0;
							.other-text-box {
								> div {
									height: 36px;
								}
							}
						}
						&.select-value-box {
							.other-text-box {
								margin-top: 12px;
							}
						}
					}
					// &:not(:last-child) {
					// margin-bottom: 4px;
					// }
				}
			}
		}

		//> other
		.radio-box {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			border-radius: 4px;
			border: 1px solid transparent;
			&.disabled {
				border: 1px solid rgba(134, 178, 200, 0.5);
			}
			&.do-disabled {
				border: 1px solid rgba(134, 178, 200, 0.5);
				background-color: rgba(116, 116, 116, 0.2);
				pointer-events: none;
			}
			&.notSubmission {
				border: 1px solid rgba(134, 178, 200, 0.5);
				background-color: rgba(134, 178, 200, 0.1);
				pointer-events: none;
			}
			.option-box {
				display: flex;
				align-items: center;
				// margin-top: 6px;
				min-width: 20%;
			}
			.custom-radio {
				&:hover {
					background-color: unset;
				}
				span {
					color: #008dc1;
				}
			}
		}
		.checkbox-box {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			border-radius: 4px;
			border: 1px solid transparent;
			&.disabled {
				border: 1px solid rgba(134, 178, 200, 0.5);
			}
			&.do-disabled {
				border: 1px solid rgba(134, 178, 200, 0.5);
				background-color: rgba(116, 116, 116, 0.2);
				pointer-events: none;
			}
			.option-box {
				display: flex;
				align-items: center;
				// margin-top: 6px;
				min-width: 20%;
			}
			.custom-checkbox {
				&:hover {
					background-color: unset;
				}
				span {
					color: #008dc1;
				}
			}
		}
		.select-box {
			.option-box {
				input {
					width: calc(100% - 40px);
				}
			}
		}
	}
}

//- control-comment-image-modal
:global {
	.control-comment-image-modal {
		.modal-dialog {
			margin-top: 12vh;
			max-width: 80vw;
			min-width: 600px;
			max-height: 80vh;
			width: auto;
			.modal-content {
				background-color: rgba(255, 255, 255, 0.9);
				.modal-header {
					padding: 1rem 1rem 0.25rem;
					border-bottom: 0;
				}
				.modal-body {
					.comment-image {
						display: block;
						margin: 0 auto;
						max-width: 100%;
						max-height: 100%;
					}
				}
			}
		}
	}
}
