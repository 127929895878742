@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
:local(.industry-layout) {
  height: 100%;
  overflow: hidden;
  :global {
    .no-nav-content-box{
        padding: 0 30px;
        .indicator-setting-content-box {
            display: flex;
            height: calc(100% - 57px);
            .right-box {
                border-left: 1px solid rgba(134, 178, 200, 0.3);
                padding: 20px 0 20px 30px;
                @include base-right-box;
            }

            .left-box {
                height: inherit;
                @include base-left-box;
                padding: 20px 30px 20px 0px;
                .tree-box {
                    height: calc(100vh - 314px);
                    overflow-y: scroll;
                    overflow-x: hidden;
                    .tree-title {
                    display: flex;
                    align-items: center;
                    line-height: normal;
                    margin-bottom: 0.75rem;
                    @include base-font;
                    @include base-font-size(14px, 500);
                    img {
                        width: 20px;
                        margin-right: 5px;
                    }
                    }
        
                    ul {
                    padding-left: 0;
                    li {
                        list-style: none;
                        color: #008dc1;
                        font-family: Noto Sans TC;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
        
                        border-radius: 4px;
                        cursor: pointer;
                        padding: 6px 20px;
                        &:hover {
                        background-color: rgba(0, 141, 193, 0.1);
                        }
        
                        &.active {
                        color: #fff;
                        background-color: #008dc1;
                        }
                    }
                    }
                }
                }
        }
    }
  }
}