:local {
  .list-box {
    position: relative;
    :global {
      .list-title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > .left {
          color: #214b62;
          font-size: 20px;
          font-weight: 500;
          white-space: nowrap;
        }
        > .right {
          display: flex;
          align-items: center;
          position: relative;
          .saveBtn {
            &.customDisabled {
              cursor: not-allowed;
              border: 1px solid #cce8f3;
              color: #cce8f3;
              background-color: #fff;
            }
          }
          .addBtn {
            &.customDisabled {
              cursor: not-allowed;
              border: 1px solid rgba(134, 178, 200, 0.5);
              background-color: rgba(134, 178, 200, 0.5);
            }
          }
        }
      }
    }
  }

  .tip-box {
    z-index: 9999;
    position: absolute;
    border-radius: 4px;
    background-color: #214b62;
    box-shadow: 2px 5px 10px 0px rgba(184, 184, 184, 0.5);
    padding: 4px 8px;
    margin-top: 4px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }

  .list-table-box {
    margin-top: 1rem;
    position: relative;
    height: calc(100vh - 255px);
    padding-right: 1px;
    width: 100%;
    :global {
      .sortable-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #dbe8ef;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #214b62;
        div {
          padding: 10px 16px;
          &:nth-child(1) {
            width: 54px;
          }
          &:nth-child(2) {
            flex: 1;
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;
          }
          &:nth-child(3) {
            width: 128px;
            text-align: center;
          }
        }
      }
      .sortable-list {
        padding-right: 2px;
        .no-data {
          text-align: center;
          padding: 10px 20px;
          color: #214b62;
          font-size: 16px;
          font-weight: 500;
          border: 1px solid #dbe8ef;
        }
        .handle-sort-list-container {
          box-shadow: 0 0 0 1px #dbe8ef;
        }
        .sortItem {
          box-shadow: 0 0 0 1px #dbe8ef;
          width: 100%;
          &.odd {
            > .itemBox {
              background-color: #f4f8fa;
            }
          }
          &.sortable-chosen {
            // box-shadow: 0 0 0 1px #dbe8ef;
            border: 1px solid #dbe8ef;
            // padding: 1px;
          }
          .itemBox {
            width: 100%;
            display: flex;
            align-items: center;
            height: 54px;
            &.focused {
              // border: 2px solid #007bff;
              background-color: #e9f7ff;
              box-shadow: 0 0 0 2px #24aaf2;
              margin: 4px 0;
              > .sortBtn {
                box-shadow: unset;
                border: 1px solid #dbe8ef;
              }
              > .operatorBox {
                box-shadow: unset;
                border: 1px solid #dbe8ef;
              }
            }
            > .sortBtn {
              box-shadow: 0 0 0 1px #dbe8ef;
              height: 100%;
              min-width: 54px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: move;
              &:hover {
                img {
                  filter: brightness(0.7);
                }
              }
            }
            > .collapsedBtn {
              height: 100%;
              min-width: 54px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              &:hover {
                img {
                  filter: brightness(0.7);
                }
              }
            }
            > .chapterIndex {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              color: #86b2c8;
              // margin: 0 1rem;
              word-break: keep-all;
              white-space: nowrap;
              min-width: 20px;
              text-align: center;
              &.noChild {
                margin-left: 1rem;
              }
            }
            > .name {
              flex: 1;
              margin: 0 1rem;
              // padding: 6px 16px;
            }
            > .operatorBox {
              min-width: 128px;
              box-shadow: 0 0 0 1px #dbe8ef;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                cursor: pointer;
                opacity: 0.8;
                &:hover {
                  opacity: 1;
                }
                &.disabled {
                  cursor: not-allowed;
                  pointer-events: all;
                  background-color: unset;
                  opacity: 0.5;
                  border: 0;
                }
                &:not(:last-child) {
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
