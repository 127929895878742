:global {
	.open-declaration-modal {
		.modal-dialog {
			margin-top: 12vh;
			width: 682px;
			.modal-header {
				padding: 8px 24px 8px 24px;
			}
			.modal-body {
				padding: 20px 24px 20px 24px;
			}
		}
	}
}

.open-declaration-modal-content {
	:global {
		.content-box {
			.row-box {
				&:not(:first-child) {
					margin-top: 1rem;
				}
				.title {
					font-size: 1rem;
					color: #214b62;
					font-weight: bold;
					margin-bottom: 0.2rem;
				}
				.data-input {
					width: 100%;
					// max-width: 400px;
				}
			}
		}
		.footer-box {
			display: flex;
			justify-content: flex-end;
			margin-top: 1rem;
			button {
				width: 125px;
				margin-left: 0.5rem;
			}
		}
		.custom-date-range-picker.react-daterange-picker {
			display: flex;
			align-items: center;
			width: 100%;
			.react-daterange-picker__wrapper {
				border: 0;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.react-daterange-picker__inputGroup {
					border-radius: 4px;
					border: 1px solid #86b2c8;
					padding: 0 16px;
					height: 42px;
					background-color: #f9fbfc;
					min-width: 268px;
          flex-grow: unset;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            background: url('../../../../public/assets/images/buttonIcon/calendar-icon.svg') no-repeat;
            background-size: contain;
            cursor: pointer;
          }
				}
				.react-daterange-picker__range-divider {
					visibility: hidden;
					position: relative;
				}

				.react-daterange-picker__range-divider::after {
					content: '~';
					color: #79a0b4;
					visibility: visible; 
					position: absolute;
					top: 0;
					left: 0;
				}
				.react-daterange-picker__clear-button,
				.react-daterange-picker__calendar-button {

					display: none;
				}

			}
		}
	}
}
