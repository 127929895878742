@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";

.normal-input-box {
  display: flex;
  align-items: center;
  width: 100%;
  //key point
  position: relative;
  height: 42px;
  min-width: 120px;

  border: 1px solid $theme-color-2;
  padding: 9px 16px;
  border-radius: 4px;
  background-color: #f9fbfc;

  &:focus-within {
    border: 1px solid #00b6e3;
    background-color: #fff;
  }
  :global { 
    // 文字
    .placeholder-item {
      left: 16px;
      @include base-font($theme-color-2, Inter);
      @include base-font-size(14px, 400);
      line-height: 24px; 
      font-size: 0;
      background-color: #f9fbfc;
      transition: all 0.2s;
      //keypoint
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  
    // 輸入框
    .seach-input {
      width: 100%;
      background-color: transparent;
      border: 0;
      z-index: 2;
      position: relative;

      &::placeholder {
        font-feature-settings: "clig" off, "liga" off;
        @include base-font($theme-color-2, Inter);
        @include base-font-size(14px, 400);
        line-height: 24px; 
      }
  
      // 沒有placeholder or foucs
      &:focus + div.placeholder-item,
      &:not(:placeholder-shown) + div.placeholder-item {
        top: 0;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px; /* 153.846% */
      }
  
      &:focus + div {
        color: #00b6e3;
        background-color:#fff;
      }
  
      &:focus {
        outline: 0;
        &::placeholder {
          font-size: 0;
        }
        background-color: #fff;
      }
  
      &:not(:placeholder-shown) ~ div.clear-btn {
        display: flex;
      }
    }
    
  
    .unit {
      color: #86b2c8;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 100% */
      white-space: nowrap;
    }
  
    .clear-btn {
      display: none;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
}

:global { 

  .invalid {   
    @include invalid;
    .placeholder-item {
      color: $color-invalid;
      background-color: $bg-invalid;
    }
  }
  .disabled {
    @include custom-disabled;
    pointer-events: none;
  }
  
}