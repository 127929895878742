@import "../../../scss/helpers/mixin";

:local(.navbar-box) {
  margin-left: 10px;
  :global {
    ul {
      margin-bottom: 0;
      padding-left: 0;
      .navbar-item-box {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        .navbar-text {
          padding: 12px 20px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          font-size: 18px;
          font-weight: 400;
          @include base-font(#008dc1);
          &.active,
          &:hover {
            background-color: #00b6e3;
            color: #fff;
          }
        }
      }
    }
  }
}
