@import "/src/scss/helpers/mixin";
select.custom-select {
    min-width: 105px;
  border-radius: 4px;
  border: 1px solid #86b2c8;
  line-height: 24px;
  padding: 6px 1rem;
  @include base-font(#214b62, Inter);
  @include base-font-size;
  &:focus{
    outline: none;
  }

  // 去除原先樣式
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../../public/assets/images/buttonIcon/dropdown-icon.svg") no-repeat;
  background-position: center right 10px;
}
