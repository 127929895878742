@import "/src/scss/helpers/mixin";

.report-generation-info-box {
  height: 100%;
  @include base-pd(0 30px);
  :global {
    .title-box {
      transition: height 0.3s;
      transition-timing-function: linear;
      // 父層header
      &.show {
        opacity: 1;
        visibility: visible;
        height: 57px;
      }
      /* 移動動畫 */
      &.hide {
        opacity: 0;
        visibility: hidden;
        height: 0;
      }
    }

    .step-box {
      display: flex;
      .step-item {
        display: flex;
        align-items: center;
        .step-item-box {
          display: flex;
          padding: 6px 8px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          @include base-font(rgba(134, 178, 200, 0.7));
          @include base-font-size;

          .step-sno {
            width: 16px;
            height: 16px;
            border-radius: 16px;
            background-color: rgba(134, 178, 200, 0.7);
            display: flex;
            padding: 6px 8px;
            justify-content: center;
            align-items: center;
            .sno {
              color: #fff;
            }
          }
          .step-name {
          }
          // 已填完
          &.filled {
            .step-sno {
              background-color: #008dc1;
            }
            .step-name {
              color: #008dc1;
            }
          }
          // 節點中
          &.active {
            .step-sno {
              background-color: #214b62;
            }
            .step-name {
              color: #214b62;
            }
          }
        }

        .step-line {
          width: 40px;
          border: 1px dashed rgba(134, 178, 200, 0.7);

          &.active {
            border: 1px solid #008dc1;
          }
        }
      }
    }

    .report-step-info-box {
      margin-top: 20px;
      height: calc(100% - 100px);
      &.full-h {
        margin-top: 0px;
        height: calc(100% - 44px);
      }
      .title-box {
        display: flex;
        align-items: start;
        // 子層相關隱藏title 圖片
        .title {
          @include base-font(#214b62);
          @include base-font-size(20px, 500);
          display: flex;
          align-items: center;
          img {
            margin-left: 12px;
            cursor: pointer;
            &.extend {
              transform: rotate(90deg);
            }
            &.non-extend {
              transform: rotate(-90deg);
            }
          }
        }

        .tool-box {
          .tool-item {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}
