@import "/src/scss/helpers/mixin";
:local(.header-box) {
  background-color: #fff;
  height: 70px;
  :global {
    .bg-body-tertiary {
      height: 70px;
      padding: 10px 30px;
      display: flex;
      justify-content: space-between;

      .nav-tool-box {
        display: flex;
        align-items: center;

        .tool-item {
          width: 24px;
          height: 24px;
        }
      }

      .split-line {
        border: 1px solid #86b2c8;
        width: 1px;
        height: 40px;
      }

      .name {
        // border-left: 1px solid;
        padding-left: 0.75rem;
        @include base-font(#86b2c8);
        @include base-font-size(12px, 500);
        letter-spacing: 2px;
        margin: 0 1rem;
      }

      .logout-text {
        cursor: pointer;
        @include base-font(#86b2c8);
        @include base-font-size(14px, 500);
        margin-left: 0.5rem;
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        &:hover {
          background-color: #e5f4f9;
        }

        // 測試機版本
        &.test-logout-text {
          margin-left: -0.5rem;
        }
      }
    }

    .header-title-box {
      display: flex;

      &:hover {
        cursor: pointer;
      }
      .title {
        color: #023c71;
        font-family: Noto Sans TC;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 2px;
      }

      .module-title {
        margin-left: 12px;
        border-radius: 20px;
        padding: 0px 8px;
        border: 2px solid #00b6e3;
        display: flex;
        align-items: center;
        color: #00b6e3;
        font-feature-settings: "clig" off, "liga" off;
        text-overflow: ellipsis;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 1px;
      }
    }

    .dropdown-menu {
      left: -86px;
    }

    .nav-dropdown {
      margin-right: 20px;

      // 原始樣式調整
      #basic-nav-dropdown {
        &::after {
          border: 0;
          position: absolute;
          left: 60px;
          top: 50%;
          transform: translateY(-50%);
          // content: url("../../../../public/assets/images/header/header-more-icon.svg");
          content: "";
        }
      }
    }

    .profile-box {
      display: flex;
      background-color: #80d8ef;
      border-radius: 40px;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      .abbr-name {
        @include base-font(#fff);
        @include base-font-size(22px, 500);
        color: #fff;
        font-feature-settings: "clig" off, "liga" off;
        letter-spacing: 2px;
      }
    }
  }
}
