@import '/src/scss/helpers/variables';
@import '/src/scss/helpers/mixin';

.disclosure-read-box {
	height: 100%;
	padding: 0 30px;

	:global {
		.title-box {
			display: flex;
			align-items: center;
			.operateBtn {
				width: unset;
			}
		}

		.content-box {
			width: 100%;
			height: calc(100% - 96px);
			border: 1px solid #dbe8ef;
			border-top: 0;
			padding: 1rem;
			margin-top: 1rem;
			border-top: 1px solid #dbe8ef;
			// display: flex;
			position: relative;
			.section-box {
				@include base-font;
				border-bottom: 1px solid #dbe8ef;
				margin-bottom: 0.5rem;
				.section-title {
					@include base-font-size(16px, 700);
				}
				.question-box {
					margin: 0.5rem 0;
					.question {
						@include base-font-size;
					}
					.control {
						margin-top: 0.5rem;
						margin-left: 26px;
					}

					.display-val {
						white-space: pre-wrap;
					}
				}
			}
		}
	}
}

:global {
	.no-data {
		display: flex;
		justify-content: center;
		width: 100%;
		align-items: center;
		height: 100%;
	}
}
