//- DisclosureSetting.module.scss
@import "src/pages/Admin/Indicator/DisclosureSetting/DisclosureSetting.module.scss";

.company-box {
  padding: 1rem;
  height: 100%;

  .search-box {
    display: flex;
    align-items: center;
    .revenue-indicator-box {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      .title {
        min-width: 100px;
        color: #214b62;
        font-family: "Noto Sans TC";
        font-style: normal;
        line-height: normal;
        font-size: 16px;
      }
    }
  }
}

.setting-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  :global {
    & .custom-checkbox {
      padding: 0;
      height: 24px;
    }
    & label {
      display: flex;
      &:hover {
        background-color: unset;
      }
    }
  }
}

.company-list-box {
  height: calc(100% - 200px);
  border-left: 1px solid #dbe8ef;
  border-right: 1px solid #dbe8ef;
}

table.setting-table {
  tbody tr {
    th,
    td {
      line-height: 24px;
    }
  }
}

.table-pagination-box {
  padding: 1rem;
  border: 1px solid #dbe8ef;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
