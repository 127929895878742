@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
:local(.more-tool-box) {
  background-color: $color-white;
  z-index: 10;
  position: absolute;
  border-radius: 4px;
  border: 1px solid #c3d9e3;
  box-shadow: 2px 5px 10px 0px rgba(217, 217, 217, 0.5);

  max-width: 200px;
  :global {
    .option-box {
      display: flex;
      flex-direction: column;

      > button {
        text-align: left;
        border: none;
        background-color: transparent;
        font-feature-settings: "clig" off, "liga" off;
        line-height: 24px;
        padding: 6px 20px;
        @include base-font($theme-color-1, Inter);
        @include base-font-size(14px, 400);
        &:hover {
          background-color: #e5f4f9;
          cursor: pointer;
        }

        &.active {
          background-color: $theme-color-1;
          color: $color-white;
        }
      }
    }
  }
}
