@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";

:local(.new-dropdown-content-box) {
  display: flex;
  align-items: center;
  width: 100%;
  //key point
  position: relative;
  height: 42px;
  min-width: 120px;
  border: 1px solid $theme-color-2;
  padding: 9px 16px;
  border-radius: 4px;
  background-color: #f9fbfc;

  &:focus-within {
    border: 1px solid $color-5;
    background-color: #fff;
  }
  :global {
    // 文字
    .placeholder-item {
      left: 16px;
      @include base-font($theme-color-2, Inter);
      @include base-font-size(14px, 400);
      line-height: 24px;
      font-size: 0;
      transition: all 0.2s;
      background-color: #f9fbfc;
      //keypoint
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    // 輸入框
    .display-input {
      width: 100%;
      background-color: transparent;
      border: 0;
      z-index: 2;
      margin-right: 35px;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &::placeholder {
        font-feature-settings: "clig" off, "liga" off;
        @include base-font($theme-color-2, Inter);
        @include base-font-size(14px, 400);
        line-height: 24px;
      }

      // 沒有placeholder or foucs
      &:focus + div.placeholder-item,
      &:not(:placeholder-shown) + div.placeholder-item {
        top: 0;
        font-feature-settings: "clig" off, "liga" off;
        line-height: 20px;
        @include base-font-size(13px, 600);
      }

      &:focus + div {
        color: $color-5;
        background-color: #fff;
      }

      &:focus {
        outline: 0;
        &::placeholder {
          font-size: 0;
        }
        background-color: $color-white;
      }

      &:focus ~ div.dropdown-icon {
        background: url("../../../../public/assets/images/buttonIcon/dropdown-active-icon.svg")
          no-repeat;
      }
    }

    .dropdown-icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      z-index: 3;
      background: url("../../../../public/assets/images/buttonIcon/dropdown-icon.svg")
        no-repeat;
    }

    .dropdown-box {
      background-color: $color-white;
      z-index: 10;
      left: 0;
      width: 100%;
      position: absolute;
      top: 41px;
      border-radius: 4px;
      border: 1px solid #c3d9e3;
      box-shadow: 2px 5px 10px 0px rgba(217, 217, 217, 0.5);
      .search-box {
        margin: 6px;
        padding: 0.5rem 1rem;
        border: 1px solid #86b2c8;
        border-radius: 4px;
        background-color: #fff;
        display: flex;
        align-items: center;
        .seach-input {
          width: 100%;
          border-right: 1px solid #86b2c8;
          border-top: 0;
          border-left: 0;
          border-bottom: 0;
          margin-right: 10px;
          &::placeholder {
            font-feature-settings: "clig" off, "liga" off;
            @include base-font(#86b2c8, Inter);
            @include base-font-size;
            line-height: 24px;
          }
          &:focus {
            outline: none;
          }
        }
      }

      .option-box {
        border-top:1px solid #c3d9e3;
        max-height: 200px;
        overflow-y: auto;
        .option-item {
          font-feature-settings: "clig" off, "liga" off;
          line-height: 24px;
          padding: 6px 0px 6px 20px;
          @include base-font($theme-color-1, Inter);
          @include base-font-size(14px, 400);
          &:hover {
            background-color: #e5f4f9;
            cursor: pointer;
          }

          &.active {
            background-color: $theme-color-1;
            color: $color-white;
          }
        }
      }
    }

    .option-title {
      padding: 6px 12px;
      @include base-font($color-1, Inter);
      @include base-font-size;
      font-feature-settings: "clig" off, "liga" off;
      line-height: 24px; /* 171.429% */
    }

    .clear-btn{
      position: absolute;
      right: 35px;
      display: flex;
      align-items: center;
      z-index: 3;
    }
  }
}

:global {
  .invalid {
    @include invalid;
    .placeholder-item {
      color: $color-invalid;
      background-color: $bg-invalid;
    }
  }
  .disabled {
    @include custom-disabled;
    pointer-events: none;
  }
}
