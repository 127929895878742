@import "/src/scss/helpers/mixin";

.normal-checkbox{  
  padding: 0;
  @extend .common-check;
}
.custom-checkbox {
  padding: 6px 8px;
  &:hover {
    background-color: #f9fbfc;
  }
  @extend .common-check;

}

.common-check{
  margin: 0;
  cursor: pointer;
  
  input[type="checkbox"] + span {
    display: inline-block;
    padding-left: 26px;
    line-height: 24px;
    background: url("../../../public/assets/images/buttonIcon/empty-check-icon.svg")
      no-repeat left 0px top 50%;
    user-select: none;
    color: #008dc1;
    &.partial {
      background: url("../../../public/assets/images/buttonIcon/partial-check-icon.svg")
        no-repeat left 0px top 50%;
    }

  }

  input[type="checkbox"] {
    display: none;

    &:checked + span {
      background: url("../../../public/assets/images/buttonIcon/check-icon.svg")
        no-repeat left 0px top 50%;
    }
    &:checked:disabled + span {
      background: url("../../../public/assets/images/buttonIcon/check-disabled-icon.svg")
        no-repeat left 0px top 50%;
    } 
    &:disabled + span {
      background: url("../../../public/assets/images/buttonIcon/uncheck-disabled-icon.svg")
        no-repeat left 0px top 50%;
    }     
  }

  //- 隱藏文字
  &.hideText {
    input[type="checkbox"] + span {
      padding-left: 16px;
      width: 16px;
      height: 24px;
    }
  }

  &.invalid {
    @include invalid;
  }
}