@import "/src/scss/helpers/mixin";
@import "/src/scss/helpers/variables";

.rg-step2-box {
  height: 100%;

  :global {
    .title-box {
      padding-top: 0;
      padding-bottom: 20px;
    }

    .result-box {
      display: flex;
      height: calc(100% - 35px);

      .left-box {
        width: 270px;
        height: 100%;
        padding: 20px 30px 0 0;
        /* 移動動畫 */
        transition: width 0.5s;
        transition-timing-function: linear;

        .toggle-img {
          cursor: pointer;
        }

        &.hide {
          width: 64px;
          padding: 20px;
          transition: width 0.5s;
          transition-timing-function: linear;

          .img-tool {
            display: flex;
            flex-direction: column;

            img {
              width: 22px;
              margin-bottom: 10px;
            }
          }
        }

        .toggle-box {
          display: flex;
          align-items: center;
          justify-content: end;
        }

        .tree-box {
          height: calc(100% - 45px);
          overflow-y: scroll;
          overflow-x: hidden;

          .tree-title {
            display: flex;
            align-items: center;
            line-height: normal;
            border-radius: 4px;
            cursor: pointer;
            padding: 6px;
            @include base-font;
            @include base-font-size(16px, 500);
          }

          .tree-item {
            .main-row {
              display: flex;
              align-items: center;
              border-radius: 4px;
              cursor: pointer;
              padding: 6px;
              @include base-font(#008dc1);
              @include base-font-size(16px);
              font-family: Noto Sans TC;

              &:hover {
                background-color: rgba(0, 141, 193, 0.1);
              }

              &.active {
                color: #fff;
                background-color: #008dc1;
              }

              .check-img {
                margin-right: 6px;
              }
            }
          }

          .overview-title-item {
            display: flex;
            @include base-font;
            @include base-font-size(16px, 500);
            font-family: Noto Sans TC;

            border-radius: 4px;
            cursor: pointer;
            padding: 6px 6px 6px 3px;

            &:hover {
              background-color: rgba(0, 141, 193, 0.1);
            }

            &.active {
              color: #fff;
              background-color: #008dc1;
            }

            img {
              width: 16px;
              margin-right: 6px;
            }
          }
        }
      }

      .center-line {
        width: 1px;
        background-color: rgba(134, 178, 200, 0.3);
        height: 100%;
      }

      .right-box {
        width: calc(100% - 270px);
        padding: 20px 0px 0px 30px;

        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 20px);

        .title-box {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .title {
            @include base-font(#214b62);
            @include base-font-size(20px, 500);
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
          }

          .tool-item {
            margin-left: 0;
            margin-right: 1rem;
          }
        }

        .manuscript-box {
          width: 100%;

          .manuscript-info {
            .title {
              @include base-font(#214b62);
              @include base-font-size(16px, 700);
              margin-bottom: 8px;
            }

            .content {
              @include base-font(#79a0b4);
              @include base-font-size(16px, 400);
            }
          }

          .ref-info {
            @include base-font(#214b62);
            @include base-font-size(16px, 400);
          }
        }

        .modal-tool-box {
          display: flex;
          padding-bottom: 20px;
          border-bottom: 1px solid #dbe8ef;

          .modal-item {
            margin-right: 1rem;
          }
        }

        .overview-box {
          height: 100%;

          .title-box {
            border-bottom: 0;
          }

          .modal-tool-box {
            border-bottom: 0;
          }

          .overview-info-box {
            border-radius: 4px;
            border: 1px solid #dbe8ef;
            padding: 20px;

            .overview-item {
              padding-bottom: 20px;
              margin-bottom: 20px;

              .overview-title {
                @include base-font(#214b62);
                @include base-font-size(16px, 700);
              }

              .overview-content {
                margin-top: 12px;
                @include base-font(#214b62);
                @include base-font-size(16px, 400);
              }

              border-bottom: 1px solid rgba(134, 178, 200, 0.3);
            }
          }
        }

        .non-overview-box {
          .title-box {
            border-bottom: 0;
          }

          .title {
            align-items: center;

            .chapt-name {
              margin-right: 16px;
            }

            .fill-type {
              padding: 1px 8px;
              border-radius: 6px;
              background-color: #fff;
              font-style: normal;
              line-height: normal;
              @include base-font;
              @include base-font-size;
              font-family: Inter;

              &.color1 {
                color: #B21016;
                border: 1px solid #B21016;
              }

              &.color2 {
                color: #138535;
                border: 1px solid #138535;
              }

              &.color3 {
                color: #007AAE;
                border: 1px solid #007AAE;
              }
            }
          }

          .draft_note {
            border: 1px solid #dbe8ef;
            padding: 10px 16px;
            color: #214B62;
            font-family: "Noto Sans TC";
            font-style: normal;
            line-height: normal;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }

    .custom-modal-width {
      width: 85%;
    }

  }
}

:global {
  .custom-preview-modal {
    .modal-dialog {
      max-width: 85%;
      min-width: 85%;
    }
  }

  .rg-step2-content-desc-box {
    overflow-y: auto;
    height: 500px;
  }

  .rg-step2-preview-box {
    overflow-y: auto;
    height: 500px;
    border-radius: 4px;
    border: 1px solid rgba(134, 178, 200, 0.3);
    padding: 20px 50px;
    color: #000;
    font-family: PMingLiU;

    .title {
      color: #273f7b;
      font-size: 16px;
      font-weight: bold;
      margin: 20px 0;
    }

    .preview-table {
      tr {
        td {
          font-family: PMingLiU;
          color: #000;
          border-color: #000;
          background-color: #fff;
          white-space: pre-line;
        }
      }
    }
  }
}