@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
:local(.email-invite-box) {
  width: 100%;
  //key point
  position: relative;
  min-width: 120px;
  border: 1px solid $theme-color-2;
  padding: 6px 16px;
  border-radius: 4px;
  background-color: #f9fbfc;
  display: flex;
  flex-wrap: wrap;

  &:focus-within {
    border: 1px solid #00b6e3;
    background-color: #fff;
  }
  :global {
    .email-box {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .email-item {
        margin: 0.25rem 0.5rem 0.25rem 0;
        flex-wrap: nowrap;
        display: flex;
        width: fit-content;
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        background-color: rgba(134, 178, 200, 0.3);
        color: #214b62;
        font-feature-settings: "clig" off, "liga" off;

        /* TWSE/Button (14, 24) */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        .delete-icon {
          margin-left: 0.5rem;
          cursor: pointer;
        }
      }

      // 輸入框
      .seach-input {
        &.any-item {
          width: 120px;
        }
        width: 100%;
        background-color: transparent;
        border: 0;
        z-index: 2;
        position: relative;
        &::placeholder {
          @include base-font(#86b2c8, Inter);
        }
        &:focus {
          outline: 0;
          &::placeholder {
            font-size: 0;
          }
          background-color: #fff;
        }
      }
    }
    
  }
}
