@import "/src/scss/helpers/mixin";
.apply-home-box {
  // background:linear-gradient(180deg, #D2F3FF 0%, #D1F5FF 23.96%, #C1EFFF 49.48%, #B8E9FF 75%, #ABE2FF 100%);

  .home-box {
    height: calc(100vh - 70px);
    overflow-y: auto;
    display: flex;
    align-items: center;
    .home-container-box {
      margin: auto;
      display: flex;
      .module-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        margin-top: 20px;
        .module-item {
          width: 220px;
          height: 240px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background-color: #fff;
          position: relative;
          overflow: hidden;
          &:hover {
            cursor: pointer;
            border: 5px solid #008dc1;
          }
          .module-icon {
            margin-bottom: 0.5rem;
            width: 45px;
          }
          .module-bg {
            opacity: 0.05;
            width: 200px;
            height: 200px;
            position: absolute;
            top: 55px;
            left: -29px;
          }
          .module-name {
            @include base-font(#008dc1);
            @include base-font-size(20px, 500);
  
            line-height: 32px;
            white-space: pre-wrap;
            text-align: center;
          }
        }
  
        .reveal-text {
          z-index: 2;
          text-align: center;
          position: absolute;
          background-color: #00c8d5;
          width: 54px;
          height: 54px;
          padding: 2px 6px;
          border-radius: 4px;
          top: -10px;
          left: 20px;
          @include base-font(#fff);
          @include base-font-size(18px);
        }
  
        .module-note {
          margin-top: 20px;
          @include base-font(#214b62, Inter);
          @include base-font-size(18px);
          line-height: 24px;
        }
      }
  
      .home-left {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .module-box {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }
        .note {
          max-width: 880px;
          white-space: pre-wrap;
          margin-top: 20px;
          padding: 0 10px;
          @include base-font(#79a0b4);
          @include base-font-size;
        }
      }
  
      .home-right {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .module-item {
          background: linear-gradient(165deg, #3CCBFF 13.48%, #0075A1 96.65%);
          height: 360px;
          &:hover {
            cursor: pointer;
            border: 5px solid #fff;
          }
          .module-bg{
              opacity: 0.1;
            width: 324px;
            height: 324px;
            position: absolute;
            top: 150px;
            left: -133px;
          }
          .module-name {
            color: #fff;
          }
  
          .module-icon {
            margin-top: 60px;
          }
          .module-btn {
            z-index: 1;
            margin-top: 60px;
            :global(.default) {
              background-color: transparent;
              border: 1px solid #fff;
            }
          }
        }
      }
    }
  
  }
}
