@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
:local(.manuscript-layout) {
  height: 100%;
  overflow: hidden;
  :global {

    .nav-content-box{
        @include base-pd;
    }
  }
}
