@import "/src/scss/helpers/variables";

.role-permission-list-box {
  padding: 1rem;
  position: relative;
  height: 100%;
}

.role-permission-list-operation-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  :global {
    & .item {
      display: flex;
      align-items: center;
      > span {
        //- no wrap
        white-space: nowrap;
        margin-right: 6px;
        color: $color-1;
      }
    }
    & .left,
    & .right {
      display: flex;
      align-items: center;
    }
    & .left {
      .item:not(:first-child) {
        margin-left: 1rem;
      }
    }
    & .right {
      .item:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }
}

.role-permission-table-box {
  position: relative;
  margin-top: 1rem;
  height: calc(100% - 120px);
  border-left: 1px solid #dbe8ef;
  border-right: 1px solid #dbe8ef;
  // overflow-y: auto;
  table.role-permission-table {
    tbody tr {
      cursor: default;
      th,
      td {
        line-height: 24px;
      }
    }
  }
}

.setting-checkbox {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  :global {
    & .custom-checkbox {
      padding: 0;
      height: 24px;
      // display: flex;
      &:hover {
        background-color: unset;
      }
      input[type="checkbox"].custom + span {
        &.checked {
          background: url("../../../../../public/assets/images/buttonIcon/check-icon.svg")
            no-repeat;
          background-position: left 0px top 50%;
        }
      }
      input[type="checkbox"].custom {
        &:checked + span {
          background: unset;
        }
      }
    }
  }
}

.edit-role-modal-content {
  position: relative;
  .content-box {
    display: flex;
    .left,
    .right {
      width: 50%;
      padding: 0 1rem;
    }
    .left {
      border-right: 1px solid $color-3;
      .item:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
  .footer-box {
    display: flex;
    margin-top: 1rem;
    .save-btn {
      margin-left: auto;
      width: 125px;
    }
  }
}

.permission-tree {
  height: auto;
  .tree-item {
    height: auto;
    &.noChild {
      margin-left: 10px;
    }
    .indentation {
      padding-left: 2rem;
    }
  }
}

.table-pagination-box {
  padding: 1rem;
  border: 1px solid #dbe8ef;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
