@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
:local(.individual-box) {
  display: flex;
  height: 100%;

  :global {
    .main-title {
      @include base-font;
      @include base-font-size(26px, 500);
    }
    .sub-title {
      @include base-font;
      @include base-font-size(20px, 500);
    }
    .result-title-box {
      height: fit-content;
      display: flex;
      align-items: center;
    }

    .right-box {
      border-left: 1px solid rgba(134, 178, 200, 0.3);
      @include base-pd;
      @include base-right-box;
      height: 100%;
      max-width: calc(100% - 64px);
      &.has-filter {
        max-width: calc(100% - 260px);
      }

      .tool-box {
        display: flex;
        justify-content: space-between;
        .tool-item-box {
          display: flex;
          @include inquiry-base-font-size;
          > div {
            margin-left: 1rem;
          }

          .switch-box {
            .icon-item {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
          }
        }
      }
      .result-box {
        margin-top: 20px;
        height: calc(100% - 60px);
        overflow-x: hidden;
        @include base-font;
        @include base-font-size(18px);

        .provider-word {
          color: #79a0b4;
        }
        .default-word {
          color: #c2370b;
        }
        .table-box {
          width: 100%;
          padding: 0 5px;
          margin-bottom: 1rem;
          th.sticky-top-41 {
            top: 41px;
          }
          td {
            white-space: pre-wrap;

            .detail-link {
              cursor: pointer;
              @include base-font(#008dc1);
              @include base-font-size(18px);
            }

            &.desc-col {
              min-width: 200px;
              text-align: left;
            }

            &.text-left {
              text-align: left;
            }
          }

          .avg-td {
            width: 20%;
          }

          .no-data-td{
            background-color: #F2F2F2;
          }
        }

      }
    }

    .left-box {
      @include base-pd;
      @include base-right-box(260px);
      /* 移動動畫 */
      transition: width 0.5s;
      transition-timing-function: linear;
      .toggle-box {
        display: flex;
        justify-content: space-between;

        img {
          width: 22px;
          cursor: pointer;
        }
      }

      .search-item-box {
        margin: 20px 0;
      }

      .empty-box {
        display: flex;
        align-items: center;
        justify-content: center;
        @include base-font(#79a0b4);
        @include base-font-size(18px);
      }

      .tree-box {
        height: calc(100% - 220px);
        overflow-y: scroll;
        overflow-x: hidden;
        .tree-title {
          display: flex;
          align-items: center;
          line-height: normal;
          margin-bottom: 0.75rem;
          @include base-font;
          @include base-font-size(18px, bold);
          img {
            width: 20px;
            margin-right: 5px;
          }
        }

        ul {
          padding-left: 0;
          li {
            display: flex;
            list-style: none;

            @include base-font(#008dc1);
            @include base-font-size(18px, bold);

            border-radius: 4px;
            cursor: pointer;
            padding: 6px 6px 6px 25px;
            &:hover {
              background-color: rgba(0, 141, 193, 0.1);
            }

            &.active {
              color: #fff;
              background-color: #008dc1;
            }

            .check-img {
              margin-right: 6px;
            }
          }
        }
      }

      &.hide {
        width: 64px;
        padding: 20px;
        transition: width 0.5s;
        transition-timing-function: linear;
        .img-tool {
          display: flex;
          flex-direction: column;
          img {
            width: 22px;
            margin-bottom: 10px;
          }
        }
      }
    }

    // 將內容文字斷開
    .table-layout-fixed {
      word-break: break-all;
    }
  }
}
