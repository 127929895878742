@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";


:global {
    .open-TableRecord-modal {
        .modal-dialog {
            margin-top: 12vh;
            width: fit-content;
        }
    }
}

.open-tableRecord-modal-content {
    :global {
        .result-box {
            max-height: 700px;
            padding-right: 15px;
            .statusTitle {
                font-size: 16px;
            }
            .originTableContainer,
            .modifiedTableContainer {
                border: 1px solid #DBE8EF;
                width: fit-content;
                padding: 0 10px;
                .originTable,
                .modifiedTable {
                    padding: 0 0 20px 0;
                    border-bottom: 2px solid #DBE8EF;
                    .tableTitle {
                        width: fit-content;
                        padding: 0 5px;
                        border-radius: 4px;
                        font-size: 16px;
                    }
                    .originTableNameDiff {
                        color:  rgba(33, 75, 98, 0.50);
                        background:  #F4F8FA;
                    }
                    .modifiedTableNameDiff {
                        color: #008DC1;
                                background:  #E5F4F9;
                                &:hover {
                                    background: var(--main-btn, #008DC1);
                                    color: white;
                                }
                    }
                    tr {
                        td {
                            min-width: 100px;
                            height: 30px;
                            padding: 0 10px;
                            border: 2px solid #DBE8EF;
                            &.changedOnOrigin {
                                color:  rgba(33, 75, 98, 0.50);
                                background:  #F4F8FA;
                            }
                            &.changedOnModified {
                                color: #008DC1;
                                background:  #E5F4F9;
                                &:hover {
                                    background: var(--main-btn, #008DC1);
                                    color: white;
                                }
                            }
                        }
                    }

                    
                }
            }

        }
    }
}