@import "/src/scss/helpers/mixin";
:local(.back-title-box) {
  // title 返回固定樣式
  // padding: 10px 0;
  :global {
    .back-box {
      display: flex;
      align-items: center;
      height: 36px;
      .title {
        font-feature-settings: "clig" off, "liga" off;
        @include base-font(#214b62);
        @include base-font-size(20px, 500);
        line-height: 36px; /* 180% */
        letter-spacing: 1px;
      }
      .back-icon {
        cursor: pointer;
        margin-right: 6px;
        height: 24px;
        width: 24px;
        background-image: url("../../../../public/assets/images/buttonIcon/back-icon.svg");
      }
    }
  }
}
