@import "/src/scss/helpers/mixin";

.custom-radio {
    padding: 6px 8px;
    margin: 0;
    cursor: pointer;
    
    &:hover{
        background-color: #F9FBFC;
    }
    
    input[type="radio"] + span {
      display: inline-block;
      padding-left: 26px;
      line-height: 24px;
      background: url("../../../public/assets/images/buttonIcon/empty-radio-icon.svg") no-repeat left 0px top 50%;
      user-select: none;
    }
  
    input[type="radio"] {
      display: none;
      &:checked + span {
        background: url("../../../public/assets/images/buttonIcon/radio-check-icon.svg") no-repeat
        left 0px top 50%;
      }
      &:checked:disabled + span{
        background: url("../../../public/assets/images/buttonIcon/radio-disabled-icon.svg") no-repeat
        left 0px top 50%;
      }
      &:disabled + span {
        background: url("../../../public/assets/images/buttonIcon/empty-radio-disabled-icon.svg")
          no-repeat left 0px top 50%;
      }     
    }
  
    &.invalid{
      @include invalid;
    }
  }