@import "./../helpers/mixin";
@import "./../helpers/variables";

table.table-container {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th {
    .sort {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .desc {
        transform: rotateX(180deg);
      }

      img {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  th,
  td {
    border: 1px solid $color-3;
    // height: 40px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }

  thead {
    tr {

      // 第一列
      &:first-child {
        th {
          &:first-child {
            border-top-left-radius: 4px;
          }

          &:last-child {
            border-top-right-radius: 4px;
            border-right: 1px solid $color-2;
          }
        }
      }

      th {
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        text-align: center;
        padding: 10px 16px;
        background-color: $color-2;

        @include base-font;
        @include base-font-size(14px, 500);
      }
    }
  }

  tbody {
    tr {

      // 最後一列
      &:last-child {
        td {
          // &:first-child {
          //   border-bottom-left-radius: 4px;
          // }
          // &:last-child {
          //   border-bottom-right-radius: 4px;
          // }
        }
      }

      &:nth-child(even) {
        background-color: $color-4;
      }

      td {
        text-align: center;
        padding: 10px 16px;
        @include base-font;
        @include base-font-size(14px, 400);
      }
    }
  }

  &.inquiry-table {
    th {
      @include base-font-size(18px, bold);
    }

    td {
      @include base-font-size(18px, 500);
    }
  }
}

.table-pagination {
  padding: 1rem;
  border: 1px solid #dbe8ef;
  background-color: #fff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

/** 凍結上方的th */
.sticky-table {

  // 鎖在 thead 才不會因為th 自適應大小而第二排的row之後難控管Top高度
  thead {
    position: sticky;
    top: 0;
    background-color: $color-white;
    z-index: 1;
  }

  // tr th {
  //   position: sticky;
  //   top: 0;
  //   background-color: $color-white;
  //   z-index: 1;
  // }
}

/** 平均所有寬度 */
.table-layout-fixed {
  table-layout: fixed;
}