@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
.summary-box {
  display: flex;
  height: 100%;
  :global {
    .main-title {
      @include base-font;
      @include base-font-size(26px, 500);
    }
    .sub-title {
      @include base-font;
      @include base-font-size(22px, 500);
    }
    .result-title-box {
      height: fit-content;
      display: flex;
      align-items: center;
    }

    .right-box {
      @include base-pd;
      border-left: 1px solid rgba(134, 178, 200, 0.3);
      height: 100%;
      @include base-right-box;

      max-width: calc(100% - 64px);
      &.has-filter {
        max-width: calc(100% - 260px);
      }

      .tool-box {
        display: flex;
        justify-content: space-between;
        .tool-item-box {
          @include inquiry-base-font-size;
          display: flex;
          > div {
            margin-left: 1rem;
          }

          .switch-box {
            .icon-item {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
          }
        }
      }
      .result-box {
        height: calc(100% - 60px);
        padding-bottom: 1rem;
        overflow-y: auto;
        overflow-x: auto;
        @include base-font;
        @include base-font-size(18px);
        .provider-word {
          color: #79a0b4;
        }
        .default-word {
          color: #c2370b;
        }

        .summary-content-box {
          height: 100%;
        }
      }
    }

    .left-box {
      @include base-pd;
      @include base-right-box(260px);
      /* 移動動畫 */
      transition: width 0.5s;
      transition-timing-function: linear;
      .toggle-box {
        display: flex;
        justify-content: space-between;

        img {
          width: 22px;
          cursor: pointer;
        }
      }

      .search-item-box {
        margin: 20px 0;
      }

      &.hide {
        width: 64px;
        padding: 20px;
        transition: width 0.5s;
        transition-timing-function: linear;
        .img-tool {
          display: flex;
          flex-direction: column;
          img {
            width: 22px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

:global {
  // 共同設定
  .common-summary {
    td {
      .detail-link {
        cursor: pointer;
        @include base-font(#008dc1);
        @include base-font-size(18px);
      }

      white-space: pre-wrap;
      &.text-right {
        text-align: right;
      }

      &.text-left {
        text-align: left;
      }

      &.text-center {
        text-align: center;
      }
    }
    // table 鎖定格額外設定
    .table-box {
      width: 100%;
      // overflow: auto;
      // max-height: calc(100% - 80px);
      .table-container {
        td,
        th {
          height: 40px;
        }
        th {
          min-width: 200px;
          &.textarea-th {
            min-width: 2000px;
          }
          &.fix-th{
            min-width: 120px;
          }
        }
        tr {
          &:nth-child(even) {
            td {
              background-color: #f4f8fa;
            }
          }
          &:nth-child(odd) {
            td {
              background-color: #fff;
            }
          }
        }
        // title
        thead {
          tr {
            // 第一列
            &:nth-child(1) {
              //- 左邊第一列也要固定
              th:nth-child(1){
                position: sticky;
                left: 0;
                top: 0;
              }
            }
          }
        }

        tbody {
          td:nth-child(1) {
            position: sticky;
            left: 0;
          }
        }
      }
    }

    .form-box {
      // overflow-y: auto;
      // overflow-x: hidden;
      // height: calc(100% - 80px);
      :global {
        .section-box {
          .block-box {
            // 有section
            margin-bottom: 1rem;
            border: 1px solid #dbe8ef;

            .block-name {
              display: flex;
              align-items: center;
              padding: 0.5rem 1rem;
              border: 1px solid #dbe8ef;
              background-color: #f9fbfc;
              font-feature-settings: "clig" off, "liga" off;
              @include base-font;
              @include base-font-size(22px, 500);

              line-height: 32px; /* 177.778% */
              img {
                margin-right: 0.5rem;
                cursor: pointer;
                width: 24px;
                height: 24px;
                &.hide {
                  transform: rotate(180deg);
                }
              }
            }
            .question-box {
              padding: 1rem;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              width: 100%;
              .question-item {
                padding: 0.5rem;
                width: 100%;
                .sub-title {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }

}
