:global {
  .fllowing-control-modal {
    .modal-dialog {
      margin-top: 10vh;
      width: 600px;
    }
  }
}

.fllowing-control-modal-content {
  height: calc(100vh - 380px);
  :global {
    .content-box {
      padding: 0.25rem 0.5rem;
      height: calc(100% - 50px);
      > .group-box {
        &:not(:first-child) {
          margin-top: 0.35rem;
        }
        > .item-box {
          &.disabled {
            border: unset;
            background-color: unset;
            pointer-events: unset;
            &.section,
            &.control {
              .setting-checkbox {
                cursor: not-allowed;
                .custom-checkbox {
                  cursor: not-allowed;
                  span {
                    color: #9bbaca;
                  }
                }
              }
            }
          }
          &.section,
          &.control {
            .setting-checkbox {
              cursor: pointer;
              &:hover {
                background-color: #e5f4f8;
                color: #214b62;
              }
              .custom-checkbox {
                &:hover {
                  background-color: unset;
                }
              }
            }
          }
          &.control {
            .setting-checkbox {
              margin-left: 1rem;
            }
            &.blank-section {
              .setting-checkbox {
                margin-left: unset;
              }
            }
          }
        }
      }
    }
    .footer-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      button {
        width: 125px;
        margin-left: 0.5rem;
      }
    }
  }
}
