.list-box {
  :global {
    .list-title-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > .left {
        color: #214b62;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}

.list-table-box {
  margin-top: 1rem;
  position: relative;
  height: calc(100vh - 345px);
  border-left: 1px solid #dbe8ef;
  border-right: 1px solid #dbe8ef;
}

.table-pagination-box {
  padding: 1rem;
  border: 1px solid #dbe8ef;
  // border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.questionnaire-table {
  th {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        margin-left: 5px;
      }
    }
  }
  td {
    :global {
      .operator-Btn {
        width: auto;
        padding: 0px 30px;
        min-width: auto;
      }
    }
  }
}

.setting-checkbox {
  :global {
    & .custom-checkbox {
      padding: 0;
      height: 24px;
    }
    & label {
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: unset;
      }
    }
  }
}
