// margin
.mt-10-p {
  margin-top: 10px;
}
.mt-15-p {
  margin-top: 15px;
}
.mt-20-p {
  margin-top: 20px;
}

.mr-1-m {
  margin-right: 1rem;
}

.ml-16-p {
  margin-left: 16px;
}
.ml-20-p {
  margin-left: 20px;
}

.mb-20-p {
  margin-bottom: 20px;
}

// padding
.pad-5 {
  padding: 5px;
}
.pad-10 {
  padding: 10px;
}
.pad-20-t {
  padding-top: 20px;
}

// width
.min-w80-p{
  min-width: 80px;
}
.min-w110-p{
  min-width: 110px;
}

.min-w120-p{
  min-width: 120px;
}

.min-w150-p{
  min-width: 150px;
}

.min-w200-p{
  min-width: 200px;
}

.max-w120-p{
  max-width: 120px;
}

.w50-p{
  width: 50px;
}

.w100-p{
  width: 100px;
}

.w110-p{
  width: 110px;
}

.w120-p{
  width: 120px;
}

.w200-p{
  width: 200px;
}

.w150-p{
  width: 150px;
}

.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}

// height
.h-fit{
  height: fit-content;
}


//mouse
.cur-pointer {
  cursor: pointer;
}

.cur-move {
  cursor: move !important;
}

.display-none {
  display: none;
}

.star {
  color: #c2370b;
}

:global {
  .invalid {
    @include invalid;
  }
  .disabled {
    @include custom-disabled;
    pointer-events: none;
  }
}
