.disclosureSetting-read {
  height: 100%;
  :global {
    .indicator-setting-content-box {
      border-top: 1px solid #dbe8ef;
      > .left-box {
        max-width: 250px;
      }
      > .right-box {
        // max-width: calc(100% - 250px);
        max-width: calc(100vw - 510px);
        position: relative;
      }
    }
  }
  .right-box-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    > .title {
      color: #214b62;
      font-size: 20px;
      font-weight: 500;
    }
    > .operators {
      > div {
        display: flex;
        align-items: center;
        button {
          margin-left: 20px;
          min-width: unset;
        }
      }
    }
  }
  .setting-table-box {
    height: calc(100% - 58px);
    overflow: auto;
    padding-bottom: 20px;
    .setting-table {
      th {
        background-color: #d5e7f1;
        font-weight: 400;
      }
      tr {
        &:nth-child(even) {
          td {
            background-color: #f4f8fa;
          }
        }
        &:nth-child(odd) {
          td {
            background-color: #fff;
          }
        }
      }
      th,
      td {
        min-width: 200px;
        text-align: center;
        // white-space: nowrap;
        line-height: 24px;
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      //- 左邊第一列也要固定
      th:first-child,
      td:first-child {
        position: sticky;
        left: 0;
      }
      //- 左邊第一個td 可以換行
      td:first-child {
        white-space: normal;
        word-break: break-word;
      }
      th:first-child {
        z-index: 3;
      }
      .setting-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        padding: 0;
        label {
          display: flex;
          &:hover {
            background-color: unset;
          }
        }
      }
    }
  }
}

.setting-checkbox {
  :global {
    & .custom-checkbox {
      padding: 0;
      height: 24px;
      &:hover {
        background-color: unset;
      }
      input[type="checkbox"].custom + span {
        &.checked {
          background: url("../../../../../public/assets/images/buttonIcon/check-icon.svg")
            no-repeat;
          background-position: left 0px top 50%;
        }
      }
      &.axit {
        margin-left: 5px;
      }
    }
  }
}
