@import "./../helpers/variables";
@import "./../helpers/mixin";


// 預設1
.default {
  border-radius: 4px;
  background-color: $theme-color-1;
  border: 1px solid $theme-color-1;
  min-width: 120px;
  padding: 6px 16px;
  text-align: center;
  width: 100%;
  @include base-font-size;
  @include base-font($color-white,Inter);
  line-height: 24px; 
  &.min-w-fit {
    min-width: fit-content;
  }
  &:hover {
    background-color: $theme-color-4;
    border: 1px solid $theme-color-4;
  }

  &.disabled{
    @include custom-disabled;
    border: 1px solid rgba(134, 178, 200, 0.50);
    background-color: rgba(134, 178, 200, 0.50);
  }
}

// 預設2
.secondary {

  min-width: 120px;
  width: 100%;

  padding: 6px 16px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid $theme-color-1;
  background-color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  @include base-font-size;
  @include base-font($theme-color-1,Inter);
  line-height: 24px; 
  &.min-w-fit {
    min-width: fit-content;
  }
  &:hover {
    background-color: $theme-color-3;
  }
  &.disabled{
    @include custom-disabled;
    border: 1px solid $theme-color-3;
    color: $theme-color-3;
    background-color: #fff;
  }
}

.img-right{
  flex-direction: row-reverse;
  img{
    margin-left: 5px;
  }
}
