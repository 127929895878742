@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";

:local(.DisclosureSettings) {
  height: 100%;
  padding: 0px;
  :global {
    .title {
        font-feature-settings: "clig" off, "liga" off;
        @include base-font(#214b62);
        @include base-font-size(20px, 500);
        line-height: 36px; /* 180% */
        letter-spacing: 1px;
    }
    button {
        min-width: initial;
        margin-left: 10px;
    }
    .setting {
        .icon-desc {
            margin-right: 16px;
            color: #79A0B4;
            font-size: 14px;
            padding-left: 25px;
            line-height: 32px;
            white-space: nowrap;
            &.require {
                background: transparent url("../../../../../public/assets/images/buttonIcon/red-rect.svg") no-repeat center left;
            }
            &.recommend {
                background: transparent url("../../../../../public/assets/images/buttonIcon/blue-triage.svg") no-repeat center left;
            }
            &.other {
                background: transparent url("../../../../../public/assets/images/buttonIcon/gray-circle.svg") no-repeat center left;
            }
        }
        button {
            border-radius: 4px;
            display: inline-flex;
            padding: 5px 16px 5px 35px;
            margin-right: 6px;
            background-color: transparent;
            &.require {
                background: transparent url("../../../../../public/assets/images/buttonIcon/red-rect.svg") no-repeat 13px center;
                color: #C2370B;
                font-size: 14px;
                border: 1px solid #CE5F3C;
                &:hover {
                    background-color: rgba(194, 55, 11, 0.10);
                }
                &.active {
                    color: #fff;
                    background: #CE5F3C url("../../../../../public/assets/images/buttonIcon/white-rect.svg") no-repeat 13px center;
                }
            }
            &.recommend {
                background: transparent url("../../../../../public/assets/images/buttonIcon/blue-triage.svg") no-repeat 13px center;
                color: #008DC1;
                font-size: 14px;
                border: 1px solid #008DC1;
                &:hover {
                    background-color: #E5F4F9;
                }
                &.active {
                    color: #fff;
                    background: #008DC1 url("../../../../../public/assets/images/buttonIcon/white-triage.svg") no-repeat 13px center;
                }
            }
            &.other {
                background: transparent url("../../../../../public/assets/images/buttonIcon/gray-circle.svg") no-repeat 13px center;
                color: rgba(77, 111, 129, 0.80);
                font-size: 14px;
                border: 1px solid rgba(77, 111, 129, 0.80);;
                &:hover {
                    background-color: rgba(33, 75, 98, 0.10);;
                }
                &.active { 
                    color: #fff;
                    background: rgba(77, 111, 129, 0.80) url("../../../../../public/assets/images/buttonIcon/white-circle.svg") no-repeat 13px center;
                }
            }
        }
    }
    .result-box {
        display: flex;
        height: calc(100% - 147px);
        .settingTable-block {
            width: 160px;
            position: sticky;
            left: 0;
            z-index: 30;
            background-color: #fff;
            height: fit-content;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
            .sticky-table {
                th {
                  white-space: nowrap;
                  min-width: 100px;
                }
                td {
                    &.capital-title {
                        color: #008DC1;
                    }
                    div {
                        height: 20.17px;
                        white-space: nowrap;
                        width: 126px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        &::-webkit-scrollbar-track-piece {
            margin-left: 160px;
        }
        .settingTable-block2 {
            z-index: 29;
            background-color: #fff;
            box-sizing: border-box;
            th {
              white-space: nowrap;
              min-width: 100px;
            }
            td {
                &.capital-title {
                    color: #008DC1;
                    white-space: nowrap;
                }
                div {
                    height: 20.17px;
                    &.icon_0 {
                        background: transparent url("../../../../../public/assets/images/buttonIcon/red-rect.svg") no-repeat center center;
                    }
                    &.icon_1 {
                        background: transparent url("../../../../../public/assets/images/buttonIcon/blue-triage.svg") no-repeat center center;
                    }
                    &.icon_2 {
                        background: transparent url("../../../../../public/assets/images/buttonIcon/gray-circle.svg") no-repeat center center;
                    }
                }
            }
        }
    }
  }
}