.scroll-to-top-button {
  position: fixed;
  // bottom: 0;
  // right: 0;
  z-index: 999;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #008dc1;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 18px;
    height: 18px;
  }
}
