@import "./variables";
// 管理屬性 需要大量重複使用到的屬性
@mixin base-font($color:$color-1, $family: "Noto Sans TC") {
  color: $color;
  font-family: $family;
  font-style: normal;
  line-height: normal;
}

/** 整體系統預設文字大小 */
@mixin base-font-size($size:14px, $weight:400) {
  font-size: $size;
  font-weight: $weight;
}

/** layout 區塊 */
@mixin base-layout-box {
  background-color: #fff;
  border-radius: 10px;
}

/** layout 左區塊 */
@mixin base-left-box($width: 300px) {
  width: $width;  
}

/** layout 右區塊 */
@mixin base-right-box($width: 100%) {
  width: $width;
}

/** 基礎padding */
@mixin base-pd($pading: 20px 30px){
  padding: $pading;
}

/** 無效的 */
@mixin invalid{
  border: 1px solid $color-invalid;
  background-color: $bg-invalid;
}

@mixin custom-disabled{
  border: 1px solid rgba(134, 178, 200, 0.5);
  background-color: rgba(116, 116, 116, 0.2);
}

/** 查詢頁面預設文字大小 */
@mixin inquiry-base-font-size($size:18px, $weight:400) {
  font-size: $size;
  font-weight: $weight;
}