@import "/src/scss/helpers/mixin";
@import "/src/scss/helpers/variables";
.admin-report-generation-box {
  padding: 0 30px;
  height: 100%;
  :global {
    .result-box {
      display: flex;
      height: calc(100% - 80px);
      .left-box {
        width: 270px;
        height: 100%;
        padding: 20px 30px 0 0;
        /* 移動動畫 */
        transition: width 0.5s;
        transition-timing-function: linear;
        .toggle-img {
          cursor: pointer;
        }
        &.hide {
          width: 64px;
          padding: 20px;
          transition: width 0.5s;
          transition-timing-function: linear;
          .img-tool {
            display: flex;
            flex-direction: column;
            img {
              width: 22px;
              margin-bottom: 10px;
            }
          }
        }
        .toggle-box {
          display: flex;
          align-items: center;
          justify-content: end;
        }
        .tree-box {
          height: calc(100% - 20px);
          overflow-y: scroll;
          overflow-x: hidden;

          .tree-title {
            display: flex;
            align-items: center;
            line-height: normal;
            border-radius: 4px;
            cursor: pointer;
            padding: 6px;
            @include base-font;
            @include base-font-size(16px, 500);
          }

          .tree-item {
            .main-row {
              display: flex;
              align-items: center;
              border-radius: 4px;
              cursor: pointer;
              padding: 6px;
              @include base-font(#008dc1);
              @include base-font-size(16px);
              font-family: Noto Sans TC;

              &:hover {
                background-color: rgba(0, 141, 193, 0.1);
              }

              &.active {
                color: #fff;
                background-color: #008dc1;
              }

              .check-img {
                margin-right: 6px;
              }
            }
          }

          .overview-title-item {
            display: flex;
            @include base-font;
            @include base-font-size(16px, 500);
            font-family: Noto Sans TC;

            border-radius: 4px;
            cursor: pointer;
            padding: 6px 6px 6px 3px;
            &:hover {
              background-color: rgba(0, 141, 193, 0.1);
            }

            &.active {
              color: #fff;
              background-color: #008dc1;
            }
            img {
              width: 16px;
              margin-right: 6px;
            }
          }
        }
      }
      .center-line {
        width: 1px;
        background-color: rgba(134, 178, 200, 0.3);
        height: calc(100vh - 245px);
      }
      .right-box {
        width: calc(100% - 270px);
        padding: 20px 0px 0px 30px;
        height: 100%;
        .title-box {
          padding: 0;
          padding-bottom: 10px;
          flex-direction: space-between;
          width: 100%;
          .title {
            @include base-font(#214b62);
            @include base-font-size(20px, 500);
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
          }

          .tool-box {

            .tool-item {
              margin-left: 0;
              margin-right: 1rem;
            }
          }
        }

        .manuscript-box {
          width: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          height: calc(100% - 90px);

          .manuscript-info {
            .title {
              @include base-font(#214b62);
              @include base-font-size(16px, 700);
              margin-bottom: 8px;
            }
            .content {
              @include base-font(#79a0b4);
              @include base-font-size(16px, 400);
            }
          }

          .ref-info {
            @include base-font(#214b62);
            @include base-font-size(16px, 400);
          }
        }

        .overview-box {
          height: 100%;
          .overview-info-box {
            height: calc(100% - 50px);
            overflow-y: auto;
            overflow-x: hidden;
            border-radius: 4px;
            border: 1px solid #dbe8ef;
            padding: 20px;
            .overview-item {
              padding-bottom: 20px;
              margin-bottom: 20px;
              .overview-title {
                @include base-font(#214b62);
                @include base-font-size(16px, 700);
              }
              .overview-content {
                margin-top: 12px;
                @include base-font(#214b62);
                @include base-font-size(16px, 400);
              }
              border-bottom: 1px solid rgba(134, 178, 200, 0.3);
            }
          }
        }

        .non-overview-box {
          .title-box{
            border-bottom: 0;
          }
          .modal-tool-box{
            display: flex;
            padding-bottom: 20px;
            border-bottom: 1px solid #dbe8ef;
            .modal-item{
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
}

:global {
  .custom-preview-modal {
		.modal-dialog {
      max-width: 85%;
      min-width: 85%;
		
		}
	}
  .admin-report-generation-detail-desc-box {
    overflow-y: auto;
    // white-space: pre-line;
    height: 500px;
  }
}
