@import "/src/scss/helpers/mixin";
textarea.custom-textarea {
  border-radius: 4px;
  border: 1px solid #86b2c8;
  background-color: #f9fbfc;
  // resize: none;
  width: 100%;
  &:focus {
    outline: none;
    border: 1px solid #00b6e3;
  }

  &:disabled{
    @include custom-disabled;
  }

  &.invalid{
    @include invalid;
  }
}
