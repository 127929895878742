@import '/src/scss/helpers/variables';
@import '/src/scss/helpers/mixin';

.search-box {
	border: 1px solid $theme-color-2;
	border-radius: 4px;
	background: #fff;
	padding: 8px 6px 8px 16px;
	display: flex;
	height: 42px;
	min-width: 120px;
	width: 100%;
	.seach-input {
		width: 100%;
		border: 0px;
		border-right: 1px solid $theme-color-1;
		&:focus {
			outline: 0;
		}

		&::placeholder {
			color: $theme-color-2;
			@include base-font($theme-color-2, Inter);
			@include base-font-size(14px, 400);
			line-height: 24px;
		}
	}

	.search-img-box {
		cursor: pointer;
		padding: 0 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 22px;
		}
	}
}
