.redirection-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #008dc1;
  font-family: Noto Sans TC;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
