@import "/src/scss/helpers/variables";

.questionnaire-box {
  padding: 1rem;
  position: relative;
}

.operation-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  :global {
    & .select-item {
      display: flex;
      align-items: center;
      > span {
        //- no wrap
        white-space: nowrap;
        margin-right: 6px;
        color: $color-1;
      }
    }
    & .left,
    & .right {
      display: flex;
      align-items: center;
    }
    & .left {
      .select-item:not(:first-child) {
        margin-left: 1rem;
      }
    }
    & .right {
      .select-item:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

.questionnaire-table-box {
  margin-top: 1rem;
  position: relative;
  height: calc(100vh - 315px);
  border-left: 1px solid #dbe8ef;
  border-right: 1px solid #dbe8ef;
}

.table-pagination-box {
  padding: 1rem;
  border: 1px solid #dbe8ef;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.questionnaire-table {
  th {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        margin-left: 5px;
      }
    }
  }
  td {
    :global {
      .operator-Btn {
        width: auto;
        padding: 0px 30px;
        min-width: auto;
      }
    }
  }
}
