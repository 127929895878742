@import "/src/scss/helpers/mixin";
@import "/src/scss/helpers/variables";
.rg-step3-box {
  height: 100%;
  :global {
    .title-box {
      padding-top: 0;
      padding-bottom: 20px;
    }

    .result-box {
      display: flex;
      height: calc(100% - 35px);
      .left-box {
        width: 270px;
        padding: 20px 30px 0 0;
        height: 100%;
        .template-box {
          overflow-y: auto;
          height: calc(100% - 80px);
          padding-right: 8px;
          .template-item {
            padding: 24px 16px;
            border-radius: 4px;
            background-color: #f9fbfc;
            border: 1px solid #dbe8ef;
            margin-bottom: 20px;
            &:hover,
            &.active {
              background-color: #e5f4f9;
              border: 1px solid #00b6e3;
              box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
              cursor: pointer;
            }

            .img-box {
              display: flex;
              justify-content: center;
              padding: 12px 0;
              border-radius: 4px;
              background: linear-gradient(
                  0deg,
                  rgba(116, 116, 116, 0.2) 0%,
                  rgba(116, 116, 116, 0.2) 100%
                ),
                #f9fbfc;
              .vertical {
                width: 112px;
              }
              .horizontal {
                width: 140px;
              }
            }

            .template-name {
              @include base-font(#86b2c8);
              @include base-font-size(16px, 700);
              line-height: 24px; /* 150% */
              margin-bottom: 8px;
            }
          }
        }

        // 左右切換按鈕
        .switch-box {
          margin-bottom: 20px;
        }
      }
      .center-line {
        width: 1px;
        background-color: rgba(134, 178, 200, 0.3);
        height: 100%;
      }
      .right-box {
        width: calc(100% - 270px);
        padding: 20px 0px 0px 30px;

        .title {
          @include base-font(#214b62);
          @include base-font-size(20px, 500);
        }
        .preview-box {
          width: 100%;
          overflow-x: auto;
          height: calc(100% - 50px);
          display: flex;

          .preview-item {
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
            margin-right: 20px;
            width: fit-content;
            height: fit-content;
            .vertical {
              max-height: calc(100vh - 370px);
            }
            .horizontal {
              width: 424px;
            }
          }
        }
      }
    }
  }
}
