@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";


:global {
    .open-draftRecord-modal {
        .modal-dialog {
            margin-top: 12vh;
            width: 916px;
        }
    }
}

.open-draftRecord-modal-content {
    display: flex;
    flex-direction: column;
    max-height: 688px;

    :global {
        .beforeAfter_text_box {
            display: flex;
            gap: 10px;

            .beforeAfter_text {
                width: 50%;
                color: #214B62;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: "Noto Sans";
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 13px;
            }
            .table_btn {
                position: absolute;
                right: 22px;
                border: none;
                background: #008DC1;
                color: white;
                border-radius: 4px;
                letter-spacing: 1px;
            }
        }

        .result-box {
            position: relative;
            display: flex;
            gap: 20px;
            width: 100%;

            .beforeEditing::-webkit-scrollbar,
            .afterEditing::-webkit-scrollbar {
                display: none;
            }

            .beforeEditing,
            .afterEditing {
                overflow-y: auto;
                width: 50%;
                height: 508px;
                border: 1px solid #dbe8ef;
                padding: 16px;

                > :first-child {
                    margin-top: -16px;
                }

                > :last-child {
                    margin-bottom: -16px;
                }

                .content_section {
                    border-bottom: 1px solid rgba(134, 178, 200, 0.30);
                    padding: 12px 0;

                    &.no-border {
                        border: none;
                    }

                    .title {
                        line-height: normal;
                        margin-bottom: 12px;
                    }

                    .highlight-before {
                        background: #F4F8FA;
                        color: rgba(33, 75, 98, 0.50);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .highlight-after {
                        color: #008DC1;
                        background: #E5F4F9;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: normal;

                        &:hover {
                            color: white;
                            background: #008DC1;
                        }
                    }

                    

                    .text {
                        display: flex;
                        white-space: pre-line; // 保留文本中的換行符
                        line-height: normal;
                        flex-wrap: wrap;
                        width: fit-content;
                        .highlight-before {
                            background: #F4F8FA;
                            color: rgba(33, 75, 98, 0.50);
                            font-size: 14px;
                            font-weight: 400;
                            line-height: normal;
                        }

                        .highlight-after {
                            color: #008DC1;
                            background: #E5F4F9;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: normal;

                            &:hover {
                                color: white;
                                background: #008DC1;
                            }
                        }
                    }
                    .griLabel {
                        display: inline;
                        box-decoration-break: clone;
                    }
                }
            }

            .scrollBarBox {
                position: relative;
                right: 5px;
                height: "560px";
                width: "12px";
                background: "transparent";
                overflow: "auto";
            }
        }
    }

}