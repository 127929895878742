@import '/src/scss/helpers/variables';
@import '/src/scss/helpers/mixin';

.disclosure-read-box {
	height: 100%;
	padding: 0 30px;

	:global {
		.title-box {
			display: flex;
			align-items: center;
			.operateBtn {
				width: unset;
			}
		}

		.content-box {
			width: 100%;
			height: calc(100% - 60px);
			display: flex;
			position: relative;
			> .left-box {
				height: 100%;
				@include base-left-box;
				width: 250px;
				padding: 20px 20px 20px 0;
				.tree-box {
					// height: calc(100% - 50px);
					overflow-y: scroll;
					overflow-x: hidden;
					.tree-title {
						display: flex;
						align-items: center;
						line-height: normal;
						margin: 6px 0;
						@include base-font;
						@include base-font-size(16px, 500);
						img {
							width: 20px;
							margin-right: 5px;
						}
					}

					ul {
						padding-left: 0;
						li {
							display: flex;
							list-style: none;
							@include base-font(#008dc1);
							@include base-font-size(16px);
							font-family: Noto Sans TC;

							border-radius: 4px;
							cursor: pointer;
							padding: 6px;
							&:hover {
								background-color: rgba(0, 141, 193, 0.1);
							}

							&.active {
								color: #fff;
								background-color: #008dc1;
							}

							.check-img {
								margin-right: 6px;
							}
						}
					}

					.overview-box {
						display: flex;
						@include base-font;
						@include base-font-size(16px, 500);
						font-family: Noto Sans TC;

						border-radius: 4px;
						cursor: pointer;
						padding: 6px 6px 6px 3px;
						&:hover {
							background-color: rgba(0, 141, 193, 0.1);
						}

						&.active {
							color: #fff;
							background-color: #008dc1;
						}
						img {
							width: 16px;
							margin-right: 6px;
						}
					}
				}

				.input-item {
					margin: 20px 0;
				}
			}
			> .right-box {
				height: 100%;
				position: relative;
				border-left: 1px solid rgba(134, 178, 200, 0.3);
				padding: 20px 0 20px 30px;
				width: calc(100% - 250px);

				.desc {
					margin-top: 1rem;
					@include base-font;
					@include base-font-size(18px, 600);
				}

				.content {
					flex: 1 1;
					margin: 20px 0;
					@include base-font(#86b2c8);
					@include base-font-size(16px, 700);
				}

				.edit-btn-box {
					margin-top: 20px;
				}

				.content-title-box {
					display: flex;
					justify-content: space-between;
					.content-tool {
						display: flex;
					}
				}
				.form-box {
					overflow-y: auto;
					overflow-x: hidden;
					height: calc(100% - 50px);

					.section-box {
						.block-box {
							// 有section
							&.real-section {
								margin-bottom: 1rem;
								border: 1px solid #dbe8ef;
								.block-name {
									padding: 0.5rem 1rem;
									border: 1px solid #dbe8ef;
									background-color: #f9fbfc;
								}

								.question-item {
									padding: 0 20px;
								}
							}

							.block-name {
								display: flex;
								align-items: center;
								font-feature-settings: 'clig' off, 'liga' off;

								@include base-font(#214b62, Noto Sans);
								@include base-font-size(20px, 700);
								line-height: 32px;
								img {
									margin-right: 0.5rem;
									cursor: pointer;
									width: 24px;
									height: 24px;
									&.hide {
										transform: rotate(180deg);
									}
								}
							}
							.question-box {
								padding: 0.5rem;
								.question-item {
									margin-bottom: 10px;

									.question {
										font-feature-settings: 'clig' off, 'liga' off;
										@include base-font(#214b62, Noto Sans);
										@include base-font-size(20px, 700);
										display: flex;
										.tip-box {
											margin-left: 0.25rem;
										}
									}

									.question-sub {
										margin: 0.25rem 0;
										@include base-font(#79a0b4);
										@include base-font-size(16px);
									}
									.control {
										width: 100%;
									}
								}
							}
						}
					}

					.hide-line-box {
						display: flex;
						align-items: center;
						.line {
							width: 100px;
							border-top: 1px dashed rgba(0, 141, 193, 0.3);
							height: 1px;
						}
						.hide-word-box {
							cursor: pointer;
							margin: 0 1rem;
							display: flex;
							align-items: center;
							color: #008dc1;
							font-family: Noto Sans TC;
							font-size: 15px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							img {
								width: 16px;
								height: 16px;
							}
						}
					}

					.preview-box {
						display: flex;
						.preview-item {
							flex: 1 1;
							margin-right: 0.5rem;
							.section-item {
								@include base-font(#214b62);
								@include base-font-size(22px, 700);
							}
							.question-item {
								padding: 1rem 0 0 1rem;
							}
							.question {
								@include base-font(#214b62, Noto Sans);
								@include base-font-size(20px, 700);
								margin-bottom: 6px;
							}
							.control {
								padding-left: 0.5rem;
								margin-bottom: 12px;
								white-space: pre-wrap;
								@include base-font(#008dc1, Noto Sans);
								@include base-font-size(18px);
							}
						}
					}
				}
			}
			.overview-box {
				.overview-all-box {
					height: calc(100% - 50px);
					margin-top: 20px;
					.overview-content-box {
						.overview-title {
							display: flex;
							@include base-font(#214b62);
							@include base-font-size(22px, 700);
							margin-bottom: 12px;
							img {
								margin-right: 6px;
							}
						}

						.overview-content {
							border-radius: 4px;
							border: 1px solid #dbe8ef;
							padding: 1rem;
							margin-bottom: 12px;
							.form-name {
								@include base-font(#008dc1);
								@include base-font-size(22px, 700);
								margin-bottom: 12px;
							}

							.overview-item-box {
								.overview-section-item {
									@include base-font(#214b62);
									@include base-font-size(22px, 700);
								}
								.overview-item {
									border-bottom: 1px solid rgba(134, 178, 200, 0.3);
									margin-bottom: 12px;
									padding: 12px 12px 0 12px;
									.overview-question-item {
										display: flex;
										flex: 1 1;
										margin-bottom: 12px;
										align-items: start;

										.overview-question {
											@include base-font(#214b62, Noto Sans);
											@include base-font-size(20px, 700);
											flex: 1;
											min-width: 200px;
											margin-right: 1rem;
										}

										.overview-control {
											flex: 3;

											@include base-font(#008dc1, Noto Sans);
											@include base-font-size(20px, 400);
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.other-text-box {
			width: 200px;
		}
	}
}

:global {
	.notice-msg-box {
		white-space: pre-wrap;
	}

	// 更正申請box
	.update-apply-box {
		.update-item {
			margin-bottom: 20px;
			.update-title {
				@include base-font(#214b62);
				@include base-font-size(16px, 700);
			}
		}

		.note {
			font-family: Noto Sans TC;
			@include base-font(#c2370b);
			@include base-font-size;
		}
	}
}

:global {
	.no-data {
		display: flex;
		justify-content: center;
		width: 100%;
		align-items: center;
		height: 100%;
	}
}
