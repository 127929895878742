:local(.switch-checkbox) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
  :global {
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

  }
}
