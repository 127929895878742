.questionnaire-edit {
  height: 100%;
  padding: 0 30px;
  position: relative;
  :global {
    .title-box {
      display: flex;
      align-items: center;
      .saveBtn {
        width: unset;
      }
    }

    .edit-box {
      width: 100%;
      height: calc(100% - 60px);
      padding: 1rem 0;
      // position: relative;
      overflow-x: hidden;

      .box {
        > .title {
          color: #214b62;
          font-size: 14px;
          font-weight: 700;
        }
      }
      .basic {
        margin-top: 1rem;
        .basicItem {
          margin-top: 1rem;
          width: 400px;
        }
      }
      .advanced {
        margin: 2rem 0 1rem;
        min-height: calc(100% - 330px);
      }
    }

    //> add-control-block
    .add-control-block {
      margin-top: 1rem;
      padding: 20px;
      border-radius: 4px;
      border: 1px dashed var(--twse-main-btn, #008dc1);
      background: var(--TWSE-sec_btn_hover, #e5f4f9);
      .button-block {
        display: flex;
        align-items: center;
        justify-content: center;
        button:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    //> control-list
    .control-list {
      margin-left: 1rem;
      padding-bottom: 10rem;
      .control-box {
        margin-top: 1rem;
        > .section-outbox,
        > .control-outbox {
          &:hover {
            .add-control-outblock {
              display: block;
            }
          }
        }
        &.sortable-chosen {
          border-left: 6px solid var(--twse-main-btn, #008dc1);
          > .section-outbox,
          > .control-outbox {
            .add-control-outblock {
              display: none;
            }
            .control-inbox {
              border-left: 0;
              .insert-control-btn {
                display: none;
              }
            }
          }
        }
        &.sort-ghost-background-class {
          background-color: #f5f5f5;
        }
        .add-control-outblock {
          padding-top: 20px;
          display: none;
          position: absolute;
          bottom: -94px;
          width: 100%;
          z-index: 3;
          &:hover {
            display: block;
          }
          .add-control-block {
            margin-top: 0;
          }
        }
        .section-outbox,
        .control-outbox {
          position: relative;
        }
        .section-inbox,
        .control-inbox {
          transition: all 0.1s;
          border-radius: 4px;
          border: 1px solid #dbe8ef;
          .operator {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
              height: 16px;
              cursor: pointer;
              &.drag {
                cursor: grab;
              }
            }
            .typeName {
              font-weight: 700;
              line-height: 24px;
              color: #86b2c8;
              margin-left: 10px;
            }
            .moveBtn {
              &:hover {
                background-color: #e5f4f8;
                border-radius: 50%;
                &.disabled {
                  background-color: unset;
                  border-radius: unset;
                }
              }
              &.disabled {
                cursor: not-allowed;
                opacity: 0.3;
                pointer-events: unset;
                border: unset;
                background-color: unset;
              }
            }
            .divideLine {
              width: 1px;
              height: 16px;
              background-color: #d3e1e7;
              margin-left: 1rem;
            }
            .left,
            .right {
              display: flex;
              align-items: center;
            }
            .left {
              img:not(:first-child) {
                margin-left: 1rem;
              }
            }
            .right {
              img:not(:first-child) {
                margin-left: 1rem;
              }
            }
          }
          .setting-checkbox {
            .custom-checkbox {
              span {
                color: #008dc1;
              }
            }
            
          }
        }
        .section-inbox {
          background-color: var(--TWSE-table_header, #dbe8ef);
          padding: 20px 20px 10px;
          .section-content-box {
            > .item-box {
              &:last-child {
                padding-bottom: 20px;
                border-bottom: 1px solid rgba(134, 178, 200, 0.3);
              }
              margin-top: 20px;
            }
          }
          .collapse-section-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            padding: 10px;
            color: var(--twse-main-btn, #008dc1);
            cursor: pointer;
            &:hover {
              opacity: 0.85;
            }
            img {
              margin-right: 5px;
            }
          }
        }
        .control-inbox {
          padding: 20px;
          background-color: #fff;
          position: relative;
          &:hover {
            border-left: 6px solid var(--twse-main-btn, #008dc1);
            .insert-control-btn {
              display: flex;
            }
          }
          &.invalid {
            border: 1px solid #c2370b;
            background-color: #fff0f0;
            .placeholder-item {
              color: #86b2c8;
              background-color: #f9fbfc;
            }
          }
          .insert-control-btn {
            display: none;
            background-color: #008dc1;
            width: 32px;
            height: 32px;
            cursor: pointer;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 10px;
            left: -18px;
            &:hover {
              background-color: #005f7b;
            }
            &.insert {
              img {
                transform: rotate(180deg);
              }
            }
          }
          .operator {
            margin-bottom: 20px;
          }
          .control-content-box {
            display: flex;
            > .left {
              width: 75%;
              padding-right: 20px;
              border-right: 1px solid rgba(134, 178, 200, 0.3);
              > .item-box {
                min-width: 300px;
                &:not(:first-child) {
                  margin-top: 20px;
                }
                &.controlType {
                  display: flex;
                  align-items: center;
                }
                .controlTypeSelectBox {
                  width: 100%;
                  max-width: 400px;
                }
                .number-control-type-deatil-box {
                  display: flex;
                  align-items: center;
                  .digitNumber-box {
                    margin-left: 1rem;
                    display: flex;
                    align-items: center;
                    span {
                      color: #214b62;
                      margin-right: 6px;
                      word-break: keep-all;
                    }
                  }
                  .setting-checkbox {
                    margin-left: 1rem;
                  }
                }
                .upload-control-comment-image-box {
                  display: flex;
                  align-items: center;
                  color: #214b62;
                  .comment-image-text {
                    margin-right: 1rem;
                  }
                  .upload-box {
                    display: flex;
                    align-items: center;
                  }
                  .upload-file-info {
                    margin-left: 1rem;
                    display: flex;
                    align-items: center;
                    .name {
                      white-space: nowrap;
                      color: #008dc1;
                      cursor: default;
                      &:hover {
                        text-decoration: underline;
                      }
                    }
                    .clear-btn {
                      margin-left: 1rem;
                    }
                  }
                }
              }
            }
            > .right {
              width: 25%;
              padding-left: 20px;
              > .item-box {
                padding: 10px 0;
              }
            }
          }
          .control-type-deatil-box {
            margin-top: 1rem;
            padding-top: 1rem;
            border-top: 1px solid rgba(134, 178, 200, 0.3);
            > .handle-sort-options-container .item-box,
            > .item-box {
              // padding: 0.5rem 0;
              padding: 0.25rem 0;
              &:not(:first-child) {
                margin-top: 1rem;
              }
              &.add-item-box {
                margin-top: 0.5rem;
                width: fit-content;
              }
              > .main-box {
                display: flex;
                align-items: center;
                > .input-box {
                  flex-grow: 1;
                }
                > img {
                  width: 24px;
                  height: 24px;
                  padding: 4px;
                  cursor: pointer;
                  &.drag {
                    cursor: grab;
                  }
                  &.ml-img {
                    margin-left: 0.5rem;
                  }
                  &.mr-img {
                    margin-right: 0.5rem;
                  }
                }
                .dropdown {
                  .dropdown-toggle {
                    &::after {
                      display: none;
                    }
                    > img {
                      width: 24px;
                      height: 24px;
                      padding: 4px;
                      cursor: pointer;
                      &.ml-img {
                        margin-left: 0.5rem;
                      }
                    }
                  }
                  .dropdown-menu {
                    .dropdown-item {
                      &:active,
                      &:focus {
                        background-color: inherit;
                        color: inherit;
                      }
                      &:hover {
                        background-color: #e5f4f8;
                        color: #214b62;
                      }
                    }
                  }
                }
              }
              .option-setting-detail {
                color: #79a0b4;
                margin-top: 0.5rem;
                padding: 0 64px 0 32px;
              }
              &.sort-ghost-background-class {
                background-color: #f5f5f5;
              }
            }
          }
        }
        .control-child-box {
          margin-left: 24px;
          padding: 2px;
        }
        &.custom-hover {
          .section-outbox,
          .control-outbox {
            &:hover {
              .add-control-outblock {
                display: none;
              }
            }
          }
          .control-inbox {
            &:hover {
              border-left: 1px solid #dbe8ef;
              .insert-control-btn {
                display: none;
              }
            }
          }
        }
      }
    }

    //> other
    .setting-checkbox {
      display: flex;
      align-items: center;
      // justify-content: center;
      & .custom-checkbox {
        padding: 0;
        // height: 24px;
        span {
          color: #214b62;
        }
      }
      & label {
        display: flex;
        &:hover {
          background-color: unset;
        }
      }
    }

    // 右側checkbox 設定
    .setting-item-box{      
      padding: 10px;
      border-bottom: 1px solid #dee2e6;
    }
  }
}
