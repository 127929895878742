@import "/src/scss/helpers/mixin";
.propaganda-file-info-box {
  height: 100%;
  padding: 0 30px;
  position: relative;
  :global {
    .title-box {
      display: flex;
      align-items: center;
      .saveBtn {
        width: unset;
      }
    }
    .edit-box {
      width: 100%;
      height: calc(100% - 75px);
      padding: 1rem 0;
      overflow-x: hidden;
      .box {
        > .title {
          color: #214b62;
          font-size: 14px;
          font-weight: 700;
        }
      }
      .basic {
        margin-top: 1rem;
        .basicItem {
          margin-top: 1rem;
          width: 400px;
        }

        .upload-box{
          
          margin-top: 1rem;
          width: 100%;
        }
      }

      .upload-file-box{
        display: flex;
        flex-wrap: wrap;
        .upload-file-item{

          display: flex;
          align-items: center;
          .upload-file-name {
            display: flex;
            align-items: center;
            margin: 0.5rem 0;
            @include base-font(#214b62);
          }
    
        }
      }

      .download-file{
        @include base-font(#214b62);
        text-decoration: underline;
        cursor: pointer;
      }
     
      .table-box {
        margin-top: 1rem;
        th > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .custom-checkbox{
      &:hover{
        background-color:unset;
      }
    }
  }
   
}

  