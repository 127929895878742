:local(.tabVerticalThin) {
    :global {
        li {
            &.disabled {
                color: rgba(134, 178, 200, 0.70) !important;
                cursor: not-allowed !important;
            }
        }

  }
}
