@import "/src/scss/helpers/mixin";
:local(.table-pagination-box) {
  display: flex;
  align-items: center;
  :global {
    .pagination-item {
      flex: 1 1;
      font-family: Noto Sans;
      @include base-font(#79a0b4);
      @include base-font-size;
    }
  
    .page-box {
      display: flex;
      justify-content: center;
      align-items: center;
      .page-input {
        margin: 0 0.5rem;
        display: flex;
        align-items: center;

        input[type="number"] {
          border-radius: 4px;
          border: 1px solid #86b2c8;
          background-color: #f9fbfc;
          line-height: 24px;
          width: 55px;
          padding: 6px 1rem;
          font-feature-settings: "clig" off, "liga" off;
          @include base-font(#214b62, Inter);
          @include base-font-size;
          &:focus {
           
            outline: none;
          }
          
          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-buftton {
            -webkit-appearance: none;
            margin: 0;
          }
        }
        .symbol {
          margin-left: 0.5rem;
          margin-right: 0.25rem;
        }
      }

      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .pagesize-box {
      display: flex;
      justify-content: end;
      align-items: center;
      select {
        margin: 0 0.5rem;
      }
    }
  }
}
