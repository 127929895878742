@import "/src/scss/helpers/mixin";
.sustain-report-twinfo-box {
  height: 100%;
  :global {
    .desc {
      margin-top: 1rem;
      @include base-font;
      @include base-font-size(16px, 600);
    }

    .secondary,
    .default {
      min-width: fit-content;
    }

    .content-title-box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .content-tool {
        display: flex;
      }
    }

    .form-box {
      border-radius: 4px;
      border: 1px solid #dbe8ef;
      background-color: #fff;
      padding: 1rem;
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100% - 80px);
      .desc {
        margin-top: 12px;
        @include base-font(#c2370b, Inter);
        @include base-font-size;
        font-feature-settings: "clig" off, "liga" off;

        line-height: 24px; /* 171.429% */
      }

      .question-box {
        .question {
          margin-bottom: 12px;
          @include base-font(#214b62);
          @include base-font-size(14px, 700);
        }
        .control {
          @include base-font-size(14px);
          font-feature-settings: "clig" off, "liga" off;
          .file-url {
            @include base-font(#214b62);
            cursor: pointer;
            text-decoration: underline;
          }
          .file-word {
            @include base-font(#008dc1);
            cursor: pointer;
            text-decoration: underline;
            .file-item {
              margin-bottom: 1rem;
            }
          }
          .upload-file-name {
            margin: 0.5rem 0;
            @include base-font(#214b62);
          }
          .view-word {
            white-space: pre-wrap;
            @include base-font(#214b62);
          }

          .item-col {
            display: flex;
            flex-direction: column;
          }
          .item {
            display: flex;
            align-items: center;
          }

          .item-content {
            max-width: 300px;
            min-width: 250px;
            margin: 0 12px;
          }
          .note {
            @include base-font(#79a0b4, Inter);
            @include base-font-size;
            font-feature-settings: "clig" off, "liga" off;

            line-height: 24px;
          }

          .special-top {
            margin-top: 6px;
          }

          .other-control {
            display: flex;
            align-items: center;
          }
        }
      }
      .line {
        width: 100%;
        border-top: 1px solid rgba(134, 178, 200, 0.3);
        height: 1px;
        margin: 20px 0;
      }
    }
  }
}
