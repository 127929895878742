@import "/src/scss/helpers/mixin";
:local(.indcator-list-box) {
  height: inherit;
  @include base-pd(0 30px);
  :global {
    .title {
      color: #214b62;
      font-feature-settings: "clig" off, "liga" off;

      /* TWSE/Page title */
      font-family: Noto Sans TC;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px; /* 180% */
      letter-spacing: 1px;
      padding: 10px 0;
      border-bottom: 2px solid #dbe8ef;
    }

    .result-box {
      max-height: calc(100% - 255px);
    }
    .desc {
      @include base-font(#79a0b4);
      @include base-font-size;
      .second-desc a{
        color: #008dc1;
      }
    }

    .table-container {
      .status {
        &.no-declared {
          color: #c2370b;
        }
      }

      .end-date {
        @include base-font(#008dc1, Inter);
        line-height: 24px; /* 171.429% */
      }

      .func-td{
        width: 125px;
      }
      .func-btn-box{
        display: flex;
        flex-direction: column;
        button{

          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
