@import '/src/scss/helpers/variables';
@import '/src/scss/helpers/mixin';

.disclosure-read-box {
	height: 100%;
	padding: 0 30px;

	:global {
		.title-box {
			display: flex;
			align-items: center;
			.operateBtn {
				width: unset;
			}
		}

		.content-box {
			width: 100%;
			height: calc(100% - 60px);
			display: flex;
			position: relative;
			border-top: 1px solid #dbe8ef;
			> .left-box {
				height: 100%;
				width: 250px;
				padding: 20px 20px 20px 0;
				@include base-left-box;

				.tree-box {
					// height: calc(100vh - 210px);
					overflow-y: scroll;
					overflow-x: hidden;
					.tree-title {
						display: flex;
						align-items: center;
						line-height: normal;
						margin-bottom: 0.75rem;
						@include base-font;
						@include base-font-size(14px, 500);
						img {
							width: 20px;
							margin-right: 5px;
						}
					}
					ul {
						padding-left: 0;
						li {
							list-style: none;
							color: #008dc1;
							font-family: Noto Sans TC;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;

							border-radius: 4px;
							cursor: pointer;
							padding: 6px 20px;
							&:hover {
								background-color: rgba(0, 141, 193, 0.1);
							}

							&.active {
								color: #fff;
								background-color: #008dc1;
							}
						}
					}
				}
			}
			> .right-box {
				height: 100%;
				position: relative;
				border-left: 1px solid rgba(134, 178, 200, 0.3);
				padding: 1.5rem 0 1.5rem 1.5rem;
				// padding: ;
				width: calc(100% - 250px);
				.sustain-report-info-box {
					height: 100%;
					> div {
						height: 100%;
					}
					.desc {
						margin-top: 0;
					}
					.content-title-box {
						display: none;
					}
					.form-box {
						height: calc(100%);
						border: 0;
					}
				}
			}
		}
	}
}

:global {
	.no-data {
		display: flex;
		justify-content: center;
		width: 100%;
		align-items: center;
		height: 100%;
	}
}
