:global {
  .prompt-modal {
    .modal-dialog {
      margin-top: 6vh;
      width: 500px;
      max-width: revert;
      .modal-header {
        border: 0;
        padding: 1rem 1rem 0;
      }
    }
  }
}
.modal-content {
  :global {
    .content-box {
      padding: 0.5rem;
      .reminder {
        color: #214b62;
      }
    }
    .footer-box {
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
      button {
        width: 125px;
        margin-left: 0.5rem;
      }
    }
  }
}
