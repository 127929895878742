@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
:local(.systemManage-layout) {
  height: 100%;
  overflow: hidden;
  :global {
    .no-nav-content-box{
        padding: 0 30px;
        
    }
  }
}