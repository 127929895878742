:local(.TabVerticalCategoryThin) {
  :global {
    .categoryUl {
      padding-left: 0;
      margin-bottom: 0;
      margin-block-start: 0px;
      margin-block-end: 0px;
      .categoryTitle {
        font-size: 14px;
        font-weight: 500;
        line-height: 20.27px;
        color: #214b62;
        pointer-events: none;
        padding: 10px 0px;
      }
    }
    .navItemsUl {
      li {
        &.disabled {
          color: rgba(134, 178, 200, 0.7) !important;
          cursor: not-allowed !important;
        }
      }
    }
  }
}
