@import "/src/scss/helpers/mixin";

.sustain-report-eninfo-box {
  height: 100%;

  :global {
    .desc {
      margin-top: 1rem;
      @include base-font;
      @include base-font-size(16px, 600);
    }
    
    .secondary,
    .default {
      min-width: fit-content;
    }

    .content-title-box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .content-tool {
        display: flex;
      }
    }

    .form-box {
      border-radius: 4px;
      border: 1px solid #dbe8ef;
      background-color: #fff;
      padding: 1rem;

      .desc {
        margin-top: 12px;
        @include base-font(#c2370b, Inter);
        @include base-font-size;
        font-feature-settings: "clig" off, "liga" off;

        line-height: 24px; /* 171.429% */
      }

      .question-box {
        .question {
            margin-bottom: 12px;
          @include base-font(#214b62);
          @include base-font-size(14px, 700);
        }
        .control {
            @include base-font-size(14px);
            font-feature-settings: 'clig' off, 'liga' off;
            .file-url{
                @include base-font(#214B62);
                cursor: pointer;
                text-decoration: underline;
            }
            .file-word{
                @include base-font(#008DC1);
                cursor: pointer;
                text-decoration: underline;
                .file-item{
                  margin-bottom: 1rem;
                }
            }

            .upload-file-name {
              margin: 0.5rem 0;
              @include base-font(#214B62);
            }
        }
      }
      .line {
        width: 100%;
        border-top: 1px solid rgba(134, 178, 200, 0.30);
        height: 1px;
        margin: 20px 0;
      }
    }
  }
}
