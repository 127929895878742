.update-apply-modal-box {
    :global{
        .btn-box {
            display: flex;
            width: 100%;
            justify-content: end;
            .btn-item {
              width: 120px;
              min-width: 120px;
            }
          }
    }
}