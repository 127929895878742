@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
:local(.ghg-reduction-info-box) {
  height: 100%;
  @include base-pd(0px 30px);
  :global {
    .title-box {
      border-bottom: 1px solid #dbe8ef;
      .tool-box {
        .tool {
          margin-right: 1rem;
        }
      }
    }
    .ghg-reduction-content-box {
      .content-title-box {
        @include base-pd(20px 0px);
        display: flex;
        justify-content: space-between;
        .content-tool {
          display: flex;
        }
      }

      .content-box {
        border: 1px solid #dbe8ef;
        padding: 1rem;
        height: calc(100vh - 280px);
        .section-box {
          @include base-font;
          border-bottom: 1px solid #dbe8ef;
          margin-bottom: 0.5rem;
          .section-title {
            @include base-font-size(16px, 700);
          }
          .question-box {
            margin: 0.5rem 0;
            .question {
              @include base-font-size;
            }
            .control {
              margin-top: 0.5rem;
              margin-left: 26px;
            }

            .display-val {
              white-space: pre-wrap;
            }
          }
        }
      }
    }
  }
}
