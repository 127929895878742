:local {
  .list-box {
    position: relative;

    :global {
      .list-title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >.left {
          color: #214b62;
          font-size: 20px;
          font-weight: 500;
          white-space: nowrap;
        }

        >.right {
          display: flex;
          align-items: center;
          position: relative;

          .saveBtn {
            &.customDisabled {
              cursor: not-allowed;
              border: 1px solid rgba(134, 178, 200, 0.5);
              background-color: rgba(134, 178, 200, 0.5);
            }
          }

          .addBtn {
            &.customDisabled {
              cursor: not-allowed;
              border: 1px solid rgba(134, 178, 200, 0.5);
              background-color: rgba(134, 178, 200, 0.5);
            }
          }
        }
      }
    }
  }

  .tip-box {
    z-index: 9999;
    position: absolute;
    border-radius: 4px;
    background-color: #214b62;
    box-shadow: 2px 5px 10px 0px rgba(184, 184, 184, 0.5);
    padding: 4px 8px;
    margin-top: 4px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }

  .list-table-box {
    margin-top: 1rem;
    position: relative;
    height: calc(100vh - 255px);
    padding-right: 1px;
    width: 100%;

    :global {
      .singleBox {
        .title {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          text-align: left;
          color: #214b62;
          margin-bottom: 1rem;
        }

        span.requireIcon {
          color: #ff0000;
          margin-left: 4px;
        }

        .desc {
          color: #79a0b4;

          code {
            color: #214b62;
          }
        }

        .addTableBtn {
          display: inline-flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #008dc1;
          cursor: pointer;
          opacity: 0.8;

          &:hover {
            opacity: 1;
          }

          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
        }

        .inputBox {

          &.griSelect,
          &.chapterTitle {
            >div {
              width: 600px;
            }
          }
        }

        .settingTableBox {
          width: 100%;
          padding: 20px;
          border: 1px solid #dbe8ef;
          border-radius: 4px;

          .inputBox {
            &.titleBox {
              display: flex;
              align-items: center;
              justify-content: space-between;

              >div {
                width: 600px;
              }

              >img {
                cursor: pointer;
                opacity: 0.8;
                width: 18px;
                height: 18px;

                &:hover {
                  opacity: 1;
                }
              }
            }
          }

          .tableRowColumn {
            display: flex;
            align-items: center;

            >div {
              width: 100px;

              .clear-btn {
                display: none;
              }
            }

            >span {
              margin: 0 10px;
            }
          }

          .tableSetting {
            overflow-y: auto;
            padding-bottom: 15px;

            .draft_note {
              border: 1px solid #dbe8ef;
              padding: 10px 16px;
              color: #214B62;
              font-family: "Noto Sans TC";
              font-style: normal;
              line-height: normal;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }

      .divideLine {
        border-top: 1px solid #86b2c880;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

      .display-input {
        width: 95%;
      }
    }
  }
}