@import "/src/scss/helpers/mixin";

.report-generation-list-box {
  height: inherit;
  @include base-pd(0 30px);
  :global {
    .title {
      @include base-font(#214b62);
      @include base-font-size(20px, 500);
      line-height: 36px; /* 180% */
      letter-spacing: 1px;
      padding: 10px 0;
      border-bottom: 2px solid #dbe8ef;
    }

    .report-generation-content-box {
      height: calc(100% - 95px);
      .result-box {
        max-height: calc(100% - 255px);
      }
      .top-desc {
        @include base-font(#c2370b);
        @include base-font-size;
      }
      .desc {
        @include base-font(#79a0b4);
        @include base-font-size;
        .ref-desc {
          text-align: justify;
          a{
  
            color: #008dc1;
  
          }

          .img-box{
            margin: 8px 0;
            .gri-img{
              width: 100px;
            }
          }
        
        }
      }
    }
  
  }
}
