@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";

:global {
    .open-draftRecord-modal {
        .modal-dialog {
            margin-top: 12vh;
            width: 100%;
        }
    }
}

.open-NecessarySetting-modal-content {
    :global {
        .result-box {
            display: flex;
            height: 432px;
            .settingTable-block {
                width: 160px;
                position: sticky;
                left: 0;
                z-index: 30;
                background-color: #fff;
                height: fit-content;
                box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
                .sticky-table {
                    th {
                      white-space: nowrap;
                      min-width: 100px;
                    }
                    td {
                        &.capital-title {
                            color: #008DC1;
                        }
                        div {
                            white-space: nowrap;
                            width: 126px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
            &::-webkit-scrollbar-track-piece {
                margin-left: 160px;
            }
            .settingTable-block2 {
                z-index: 29;
                background-color: #fff;
                box-sizing: border-box;
                th {
                  white-space: nowrap;
                  min-width: 100px;
                }
                td {
                    &.changedTable{
                        background: #E5F4F9;
                        &:hover {
                            border: 1px solid  #00B6E3;
                        }
                    }
                    &.capital-title {
                        color: #008DC1;
                        white-space: nowrap;
                    }
                    .icon_container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    div {
                        min-height: 20px;
                        min-width: 20px;
                        &.icon_0 {
                            background: transparent url("../../../../public/assets/images/buttonIcon/red-rect.svg") no-repeat center center;
                        }
                        &.icon_1 {
                            background: transparent url("../../../../public/assets/images/buttonIcon/blue-triage.svg") no-repeat center center;
                        }
                        &.icon_2 {
                            background: transparent url("../../../../public/assets/images/buttonIcon/gray-circle.svg") no-repeat center center;
                        }
                        &.lightBlue-icon_0 {
                            background: transparent url("../../../../public/assets/images/buttonIcon/lightBlue-rect.svg") no-repeat center center;
                        }
                        &.lightBlue-icon_1 {
                            background: transparent url("../../../../public/assets/images/buttonIcon/lightBlue-triage.svg") no-repeat center center;
                        }
                        &.lightBlue-icon_2 {
                            background: transparent url("../../../../public/assets/images/buttonIcon/lightBlue-circle.svg") no-repeat center center;
                        }
                        &.lightBlue-icon_null {
                            display: none;
                        }
                        &.greaterIcon {
                            background: transparent url("../../../../public/assets/images/buttonIcon/Greater-icon.svg") no-repeat center center;
                        }
                    }
                    
                }
                tr {
                    max-height: 42px;
                }
            }
        }
    }
}