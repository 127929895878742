@import "/src/scss/helpers/variables";
@import "/src/scss/helpers/mixin";
:local(.sysFunction-list) {
  height: 100%;
  padding: 10px 20px;
  :global {
    .tool-box {
      display: flex;
      justify-content: space-between;
      .sel-item {
        color: #214B62;
        font-family: Noto Sans TC;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-right: 16px;
        margin-right: 10px;
        border-right: 1px solid rgba(134, 178, 200, 0.30);
        height: 20px;
      }
      .icon-button {
        margin: 0 4px;
      }
    }
    .result-box {
        margin-top: 20px;
        display: flex;
        max-height: calc(100% - 200px);
        .table-box {
          width: 100%;
          padding: 0;
        }
      }
      .page {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
  }
}