@import "/src/scss/helpers/mixin";
// 以元件來區分
:local(.sidebar-box) {
  position: sticky;
  top: 0;
  margin-top: 73px;
  width: 40px;
  /* 移動動畫 */
  transition: width 0.5s;
  transition-timing-function: linear;
  &.show{
    width: 157px;
  }
  :global {
    .extend-icon {
      position: absolute;
      right: 10px;
      top: -30px;
      img {
        width: 22px;
        cursor: pointer;

        &.right{
          transform: rotate(180deg);
        }
      }
    }
    .sidebar-content-box {
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - 170px);
      ul {
        padding: 10px 0;
        li {
          list-style: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 6px 0;

          .divide-line {
            width: 140px;
            border-top: 1px solid rgba(134, 178, 200, 0.3);
            margin: 6px 0 6px 10px;
          }

          .reveal-text {
            text-align: center;
            position: absolute;
            background-color: #00c8d5;
            width: 32px;
            height: 32px;
            padding: 2px 6px;
            border-radius: 4px;
            top: 0;
            left: 0;
            @include base-font(#fff);
            @include base-font-size(10px);
          }

          .sidebar-link {
            position: relative;
            width: 146px;
            min-width: 146px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding: 12px 10px;

            img {
              width: 32px;
            }
            .sidebar-text {
              white-space: pre-wrap;
              margin-top: 0.5rem;
              color: #008dc1;
              font-size: 14px;
              font-weight: 400;
              text-align: center;
            }

            &.flex-row {
              img {
                width: 20px;
              }
              .sidebar-text {
                margin-left: 0.5rem;
                margin-top: 0;
              }
            }
            &.active {
              background-color: #fff;
              margin-left: 10px;
            }
            &:hover {
              margin-left: 10px;
            }
          }
        }
        .sidebar-li {
          padding: 0;
        }
      }
    }
  }
}
