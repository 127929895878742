@import "/src/scss/helpers/mixin";

:local(.tip-box) {
    z-index: 9999;
    position: absolute;
    border-radius: 4px;
    background-color: #008dc1;
    box-shadow: 2px 5px 10px 0px rgba(184, 184, 184, 0.5);
    padding: 0.25rem;
    @include base-font(#fff);
    @include base-font-size(14px, 500);
}