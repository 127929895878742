.triangle-accordion {
  height: auto;
}

:global {
  .toggle-header {
    display: flex;
    align-items: center;
    &.isClose {
      .toggle-icon {
        transform: rotate(-90deg);
      }
    }
    .toggle-icon {
      margin-right: 6px;
      // line-height: 20px;
      transition: transform 0.3s ease;
    }
  }
}
