
$font-16: 16px;  
$font-18: 18px;

// 寬度
$w-260: 260px;

// color

$theme-color-1: #008DC1;
$theme-color-2: #86B2C8;
$theme-color-3: #cce8f3;
$theme-color-4:#33A4CD;

$color-1: #214B62;
$color-2:#d6ebf4;
$color-3: #dbe8ef;
$color-4:#f4f8fa;
$color-5: #00b6e3;
$color-6: #023c71;

$color-white: #fff;
$color-invalid:#c2370b;

$bg-invalid:#fff0f0;