.questionnaire-response-detail {
  height: 100%;
  padding: 0 30px;
  :global {
    .title-box {
      display: flex;
      align-items: center;
      .saveBtn {
        width: unset;
      }
    }
    .detail-content-box {
      padding: 1rem 0;
      height: calc(100% - 70px);
      position: relative;
      .detail-box {
        &:not(:first-child) {
          margin-top: 1rem;
        }
        .table-box {
          overflow-x: auto;
          width: 100%;
          table.table-container {
            th {
              background-color: #d5e7f1;
              color: #214b62;
              font-weight: 400;
            }
            td {
              color: #214b62;
              font-weight: 400;
            }
            tr {
              &:nth-child(even) {
                td {
                  background-color: #f4f8fa;
                }
              }
              &:nth-child(odd) {
                td {
                  background-color: #fff;
                }
              }
            }
            th,
            td {
              min-width: 200px;
              text-align: center;
              // white-space: nowrap;
              line-height: 24px;
            }
            //- 左邊第一列也要固定
            th:first-child,
            td:first-child {
              position: sticky;
              left: 0;
              z-index: 1;
            }
            //- 左邊第一個td 可以換行
            td:first-child {
              white-space: normal;
              word-break: break-word;
            }
            th:first-child {
              z-index: 3;
            }
          }
        }
      }
    }
    .comment {
      color: #79a0b4;
      font-size: 14px;
      margin-bottom: 1rem;
    }
  }
}

:global {
  .layout-container-box {
    overflow: hidden;
  }
  .no-data {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100%;
  }
}
