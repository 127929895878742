@import "/src/scss/helpers/variables";

:local(.images-box) {
  width: 100%;
  height: calc(100vh - 70px);
  padding: 1rem;
  overflow-x: hidden;

  :global {
    .title {
      font-size: 20px;
      font-weight: 700;
      color: $color-5;
    }

    .row {
      border: 1px solid #008dc1;
      padding: 1rem;
      border-radius: 4px;
      .component-title {
        color: cornflowerblue;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
      }
    }
  }

  //- image-display-box
  .image-display-box {
    display: flex;
    flex-direction: column;
    .images-folder-root-path {
      color: #214b62;
      margin-top: 0.5rem;
    }
    .image-box {
      padding: 0.25rem 0.5rem;
      border-right: 1px solid #214b6240;
      > div {
        color: #214b62;
      }
      > img {
        max-width: 48px;
        max-height: 48px;
      }
    }
  }
}
