.empty-search-page-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .empty-msg{
        white-space: pre-wrap;
    }
    .link{
        cursor: pointer;
        color: #0d6efd;
    }
}