:local(.entrance-box) {
    padding: 1rem;
    :global{
        .form-box {
          height: calc(100vh - 100px);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .form-item{
            width: 220px;
            padding: 0.5rem;
          }
        }
    }
}