@import "/src/scss/helpers/mixin";

.export-file-box {
  padding: 1.5rem 30px;
  position: relative;


  .operation-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #86b2c84d;
    padding-bottom: 1rem;

    :global {
      .select-item {
        width: 160px;
      }
    }
  }

  :global {
    .data-box {
      margin-top: 1rem;
      position: relative;
      height: calc(100vh - 345px);
      overflow-y: auto;
      overflow-x: hidden;

      .btn-box{
        width: 120px;
      }
    }
  }
}

.no-data {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100%;
}
