@import "/src/scss/helpers/mixin";
.error-boundary-fallback-box {
  .error-content {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #E19B85;
    @include base-font(#C2370B);
    @include base-font-size(14px, 500);
    padding: 1rem;
    width: fit-content;
  }
}
