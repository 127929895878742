@import "/src/scss/helpers/mixin";
:local(.batch-upload-modal-box) {
  :global {
    .modal-title {
      @include base-font(#023c71);
      @include base-font-size(20px, 500);
      font-feature-settings: "clig" off, "liga" off;
      line-height: 36px;
      letter-spacing: 1px;
    }

    .batch-content-box {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .sample-word {
      @include base-font(#008dc1, Inter);
      font-feature-settings: "clig" off, "liga" off;
      line-height: 24px;
      cursor: pointer;
    }

    .note, .warning-box{
      margin: 1rem 0;
      @include base-font(#C2370B);
      @include base-font-size;
    }

    // 上傳區域
    .upload-box {
        width: 100%;
      border-radius: 10px;
      border: 1px dashed #86b2c8;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      margin: 1rem 0;
      @include base-font(rgba(33, 75, 98, 0.5));
      font-style: normal;
      line-height: 29px;
      img {
        margin: 18px 0;
      }

      &:hover,
      &.active {
        border: 1px dashed #008dc1;
        background-color: #f4f8fa;
        cursor: pointer;
      }

    }

  }
}
